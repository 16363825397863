import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import E1Request from '../classes/E1Request';
import Form from '../classes/Form';
import GenericList from '../classes/GenericList';
import { pickDataAndCellMetadata } from '../utils/datatable';
import { isInConstructionMode } from './construction_util';
import { monitorTrades } from './stage_matrix_trades';

/*
E.g.
  confirmedDocCount: "2"
  discipline: null
  fullSet: true
  id: 1930317
  order_index: null
  quotesDueDate: "2021-09-02"
  title: "Full Set"
  unconfirmedDocCount: "0"
 */
export type PackageTablePackage = {
  confirmedDocCount: string;
  discipline: string | null;
  fullSet: boolean;
  id: number;
  // eslint-disable-next-line camelcase
  order_index: number | null;
  quotesDueDate: string;
  title: string;
  unconfirmedDocCount: string;
};

type PackageResponse = {
  success: true;
  data: PackageTablePackage[];
};

export const triggerGeneratePackagesModal = async (stageId: number): Promise<void> => {
  const packageModalPath = Routing.generate('app_stagepackage_generatemodal', { id: stageId });

  await new E1Request(packageModalPath).submit();

  const $tradeForm = $('.trade-select');
  if ($tradeForm.length > 0) {
    monitorTrades($tradeForm);
  }
};

const checkUnmappedTrades = async (stageId: number) => {
  const fetchUnmappedTradesPath = Routing.generate('app_stagepackage_fetchunmappedtrades', {
    id: stageId,
  });

  const { unmappedTrades, unmappedMessage } = await new E1Request<{
    success: true;
    unmappedTrades: Record<string, unknown>[]; // really a Trade[] but we only care about the count
    unmappedMessage: string;
  }>(fetchUnmappedTradesPath).submit();

  const $target = $('.unmapped-trades-container');

  if (unmappedTrades) {
    const tradeCount = unmappedTrades.length;
    $target.find('span.message').text(unmappedMessage);
    $target.toggleClass('hide', tradeCount === 0);
  }
};

$(() => {
  const $body = $('body');
  const stageIdString = $('div.wizard-main').attr('data-stage');
  const stageId = stageIdString ? parseInt(stageIdString, 10) : undefined;

  if (!stageId) {
    return;
  }

  const $packagesContainer = $('.packages-container');
  if ($packagesContainer.length === 0) return;

  // Package utils
  $body.on('click', '.transmit_package_trigger', ({ currentTarget }) => {
    const $transmitPackageTrigger = $(currentTarget);
    const id = $transmitPackageTrigger.attr('data-stage');
    const packageId = $transmitPackageTrigger.attr('data-package');

    const transmitMultipleModalPath = Routing.generate(
      'app_constructionstagepackage_transmitmultiplemodal',
      {
        id,
      },
    );

    return new E1Request(transmitMultipleModalPath, 'GET', { packageId })
      .setShowLoadingModal()
      .submit();
  });

  $body.on('click', '.edit_package_trigger', ({ currentTarget }) => {
    const $editPackTrigger = $(currentTarget);
    const id = $editPackTrigger.attr('data-stage');
    const packageId = $editPackTrigger.attr('data-package');

    const editModalPath = Routing.generate('app_stagepackage_editmodal', {
      id,
      packageId,
    });

    return new E1Request(editModalPath).setShowLoadingModal().submit();
  });

  $body.on('click', '.delete_package_trigger', ({ currentTarget }) => {
    const $deletePackTrigger = $(currentTarget);
    const id = $deletePackTrigger.attr('data-stage');
    const packageId = $deletePackTrigger.attr('data-package');

    const deleteModalPath = Routing.generate('app_stagepackage_deletemodal', {
      id,
      packageId,
    });

    return new E1Request(deleteModalPath).setShowLoadingModal().submit();
  });

  $body.on('click', '.add_package_trigger', ({ currentTarget }) => {
    const id = $(currentTarget).attr('data-stage');

    const newPackageModalPath = Routing.generate('app_stagepackage_newmodal', { id });

    return new E1Request(newPackageModalPath).setShowLoadingModal().submit();
  });

  $body.on('click', '.download_package_trigger', ({ currentTarget }) => {
    const $downloadPackageTrigger = $(currentTarget);
    const id = $downloadPackageTrigger.attr('data-stage');
    const packageId = $downloadPackageTrigger.attr('data-package');

    const downloadPackagePath = Routing.generate('app_stagepackage_download', {
      id,
      packageId,
    });

    return new E1Request(downloadPackagePath).submit();
  });

  $body.on('click', '.map-trades-trigger', async ({ currentTarget }) => {
    const id = $(currentTarget).attr('data-stage');
    const mapTradesModalPath = Routing.generate('app_stagepackage_maptradesmodal', { id });

    await new E1Request(mapTradesModalPath).setShowLoadingModal().submit();

    const $tradeForm = $('.trade-select');
    if ($tradeForm.length > 0) {
      monitorTrades($tradeForm);
    }
  });

  const $packagesTable = $('table.packages-table');
  if ($packagesTable.length === 0) return;

  const formatDateColumn = (data: string, type: 'display' | 'filter'): string => {
    if (type === 'display' || type === 'filter') {
      return data ? moment(data).format('DD MMM YYYY') : '-';
    }

    return data;
  };

  const $placeholderRow = $packagesTable.find('tr.data-table-placeholder').first();

  const columns: DataTables.ColumnSettings[] = [
    {
      data: 'title',
      render: $.fn.dataTable.render.text(),
    },
    {
      data: 'discipline',
      orderData: [1, 0],
      render: $.fn.dataTable.render.text(),
    },
    {
      data: 'confirmedDocCount',
      render: pickDataAndCellMetadata<PackageTablePackage>(
        ({ confirmedDocCount, unconfirmedDocCount }, { col }) => {
          const template = $placeholderRow.find('td').get(col);
          const $content = template ? $(template).find('.package-details-info').first() : undefined;
          if (!$content) {
            return undefined;
          }

          $content.find('.confirmed').text(confirmedDocCount);
          if (parseInt(unconfirmedDocCount) > 0) {
            $content.find('.pending').text(`${unconfirmedDocCount} draft`).removeClass('hide');
          } else {
            $content.find('.pending').text('').addClass('hide');
          }

          return $content.prop('outerHTML');
        },
      ),
    },
    {
      data: null,
      orderable: false,
      render: pickDataAndCellMetadata<PackageTablePackage>(
        ({ id, confirmedDocCount, fullSet }, { col }) => {
          const template = $placeholderRow.find('td').get(col);
          const $template = template ? $(template).clone() : undefined;
          const $content = $template?.find('.btn-group').first();

          if (!$content) {
            return undefined;
          }

          // Only show transmit button for packages that have docs
          if (parseInt(confirmedDocCount) > 0) {
            const $transmitLink = $content.find('.transmit_package_trigger');

            $transmitLink
              .removeClass('hidden')
              .attr('data-stage', stageId)
              .attr('data-package', id);
          }

          const $editLink = $content.find('.edit_package_trigger');
          const $delLink = $content.find('.delete_package_trigger');

          if (fullSet) {
            $editLink.closest('li').remove();
            $delLink.closest('li').remove();
            $content.find('li.divider').remove();
          } else {
            [$editLink, $delLink].forEach(($link) => {
              $link.attr('data-stage', stageId).attr('data-package', id);
            });
          }

          // Attach data attributes to download package button
          $content
            .find('.download_package_trigger')
            .attr('data-stage', stageId)
            .attr('data-package', id);

          return $content.prop('outerHTML');
        },
      ),
    },
    {
      data: 'order_index',
      visible: false,
    },
  ];

  const showQuotesDueCol = !isInConstructionMode();

  if (showQuotesDueCol) {
    const quotesDueCol: DataTables.ColumnSettings = {
      data: 'quotesDueDate',
      render: formatDateColumn,
    };

    columns.splice(2, 0, quotesDueCol);
  }

  /**
   * Zero-indexed {@link https://datatables.net/examples/basic_init/table_sorting.html}
   */
  const orderIndexPosition = columns.findIndex(({ data }) => data === 'order_index');

  const packagesDataTable = new GenericList<PackageResponse, PackageTablePackage[]>(
    $packagesTable,
    (list) => {
      // eslint-disable-next-line fp/no-mutation, no-param-reassign
      list.table = list.$target.DataTable({
        pageLength: 15,
        data: list.data,
        order: [[orderIndexPosition, 'asc']],
        columns,
      });
      list.toggleTableDisplay(true);
      list.$target.closest('.loading-container').addClass('has-loaded');
    },
    Routing.generate('app_stagepackage_fetch', { id: stageId }),
    ({ data }) => data,
  );

  packagesDataTable.$target.on('list-data-updated', async () => {
    if (packagesDataTable.data.length === 0) {
      return triggerGeneratePackagesModal(stageId);
    }

    return checkUnmappedTrades(stageId);
  });

  $body.on('submit', 'form.edit-package-form', ({ currentTarget }) => {
    const editPackageForm = new Form($(currentTarget));

    // eslint-disable-next-line fp/no-mutation
    editPackageForm.extraCallback = () => {
      packagesDataTable.updateTable(true);
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    editPackageForm.submit();
    return false;
  });

  $body.on('submit', 'form.delete-package-form', ({ currentTarget }) => {
    const deletePackageForm = new Form($(currentTarget));

    // eslint-disable-next-line fp/no-mutation
    deletePackageForm.extraCallback = () => {
      packagesDataTable.updateTable(true);
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    deletePackageForm.submit();

    return false;
  });
});
