import $ from 'jquery';
import Routing from 'routing';
import AnalyticsService from '../classes/AnalyticsService';
import Modal from '../classes/Modal';

function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function getReferrerUriIfInIframe() {
  if (isInIframe()) {
    try {
      return document.referrer;
    } catch (e) {
      return 'unknown';
    }
  }
  return null;
}

function recordPageView() {
  window.analyticsService = new AnalyticsService();
  window.analyticsService.addEventEndpoint = Routing.generate('itp_ajax_analytics_event');
  window.analyticsService.addEvent('itp-interact', {
    action: 'ViewPage',
    iframe_referrer: getReferrerUriIfInIframe(),
  });
}

function showConfirmModal() {
  const $confirmModal = $('.e1-modal.confirm-modal');
  if ($confirmModal.length > 0) {
    const modal = new Modal($confirmModal);
    modal.show();
  }
}

$(() => {
  recordPageView();
  showConfirmModal();
});
