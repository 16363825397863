import $ from 'jquery';
import E1Request from './E1Request';
import BasePersistentStateDataTable from './BasePersistentStateList';

export default class GenericList extends BasePersistentStateDataTable {
  constructor(target, initFunc, fetchUrl, transformer, dataCallback) {
    super(target, initFunc, fetchUrl, transformer);
    this.dataCallback = dataCallback;
    this.fetch(this.init);
  }

  static defaultTransformer(response) {
    return response.data;
  }

  init(self) {
    super.init(self);
    self.replaceDefaultColVisButton();
  }

  setTable(table) {
    this.table = table;
  }

  setFetchUrl(url) {
    this.fetchUrl = url;
  }

  async fetch(onFetch) {
    const callbackSet = typeof onFetch === 'function';

    if (this.fetchUrl) {
      let filterData = {};

      if (this.isFilterAware && this.filterOptions.length === 0) {
        setTimeout(() => {
          $(document).trigger('data-table-requires-filter');
        }, 100);
        if (callbackSet) {
          onFetch(this);
        }
        return;
      }

      if (this.isFilterAware && this.filterOptions) {
        filterData = { filter_options: this.filterOptions };
      }

      const response = await new E1Request(this.fetchUrl, 'GET', filterData).submit();

      this.data = [];
      let transformedResponseData = await Promise.resolve(this.transformer(response));
      const responseType = typeof transformedResponseData;
      if (responseType !== 'undefined') {
        if (responseType === 'string') {
          transformedResponseData = JSON.parse(transformedResponseData);
        }
        transformedResponseData.forEach((responseDatum) => {
          let data = responseDatum;
          if (typeof this.dataCallback === 'function') {
            data = this.dataCallback(this, data);
          }
          this.data.push(data);
        });
      }
      this.$target.trigger('list-data-updated');

      if (callbackSet) {
        onFetch(this);
      }
    } else if (callbackSet) {
      onFetch(this);
    }
  }

  getData() {
    return this.data;
  }

  drawTable(cb) {
    this.toggleTableDisplay();

    if (this.table !== null) {
      this.table.rows().remove();
      this.table.rows.add(this.data).draw();
    }
    this.$target.trigger('data-updated');

    if (typeof cb === 'function') {
      cb();
    }
  }

  updateTable(fetch, cb) {
    if (fetch) {
      this.fetch(() => {
        $.proxy(this.drawTable(), this);
        if (typeof cb === 'function') {
          cb();
        }
      });
    } else if (typeof cb === 'function') {
      this.drawTable(cb);
    }
  }
}
