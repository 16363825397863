import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';
import Form from '../classes/Form';
import { searchAddressBook } from './addressbook';

$(() => {
  const $rfqTender = $('.rfq-tender');
  const $body = $('body');

  if ($rfqTender.length > 0) {
    const $request = $rfqTender.attr('data-request');
    const id = $rfqTender.attr('data-stage-id');
    const slug = $rfqTender.attr('data-slug');

    function loadRequestModal() {
      const requestNew = Routing.generate('itp_slugstage_requestmodal', {
        slug,
        id,
      });
      const req = new E1Request(requestNew, 'GET');
      req.show_loading_modal = true;
      req.submit();
    }

    $body.on('click', '.requestPackage', () => {
      loadRequestModal();
    });

    $body.on('submit', 'form.request-package-form', function (e) {
      e.preventDefault();

      const $form = $(this);
      const magicForm = new Form($form);

      magicForm.submit();
      return false;
    });

    if ($request == 1) {
      if ($('.requestPackage').length > 0) {
        loadRequestModal();
      } else {
        searchAddressBook(slug);
      }
    }
  }
});
