import jQuery from 'jquery';

(() => {
  const quoteStatusOrder = ['quoted', 'quoting', 'declined', 'no_response'];

  const getStatusFromClassString = (classes: DOMTokenList | undefined) => {
    if (classes?.contains('quoted')) {
      return quoteStatusOrder.indexOf('quoted');
    }
    if (classes?.contains('quoting')) {
      return quoteStatusOrder.indexOf('quoting');
    }
    if (classes?.contains('declined')) {
      return quoteStatusOrder.indexOf('declined');
    }
    return quoteStatusOrder.indexOf('no_response');
  };

  const quoteStatusSort = (a: string, b: string) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const parsedHTMLNodeA = $.parseHTML(a)[0] as Element;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const parsedHTMLNodeB = $.parseHTML(b)[0] as Element;
    const classListA: DOMTokenList | undefined =
      parsedHTMLNodeA.querySelector('.rel-status')?.classList;
    const classListB: DOMTokenList | undefined =
      parsedHTMLNodeB.querySelector('.rel-status')?.classList;
    const rowAQuoteStatus = getStatusFromClassString(classListA);
    const rowBQuoteStatus = getStatusFromClassString(classListB);

    if (rowAQuoteStatus === rowBQuoteStatus) {
      return 0;
    }

    if (rowAQuoteStatus < rowBQuoteStatus) {
      return 1;
    }

    return -1;
  };

  jQuery.extend(jQuery.fn.dataTable.ext.oSort, {
    'quoteStatus-asc': (a: string, b: string) => quoteStatusSort(a, b),

    'quoteStatus-desc': (a: string, b: string) => quoteStatusSort(a, b) * -1,
  });
})();
