import $ from 'jquery';
import Routing from 'routing';
import _ from 'underscore';
import E1Request from './E1Request';
import { isValidKeyup } from '../utils/helpers';

export default class BasePersistentStateDataTable {
  constructor(target, initFunc, fetchUrl, transformer) {
    // todo: use new.target when babel supports it; see https://github.com/babel/babel/issues/1088
    if (this.constructor.name === BasePersistentStateDataTable) {
      throw new TypeError('You attempted to instantiate incomplete base class');
    }
    this.data = [];
    this.$target = target;
    this.table = null;
    this.tableDefaults = {};
    this.init_func = initFunc;
    this.fetchUrl = fetchUrl;
    this.transformer = transformer || this.constructor.defaultTransformer;
    this.initialOrderingDone = false;

    this.$container = this.$target.closest('.loading-container');
    this.$emptyPlaceholder = this.$container.find('.empty-text-placeholder');
    this.$placeholderRow = this.$target.find('tr.data-table-placeholder').first().clone();
    this.$colVisButtonContainer = $();
    this.tableShowing = true;

    this.reapplySearch = false;
    this.tableName = this.$target.data('table-name');
    this.persistenceEnabled = this.$target.data('server-persistence');

    this.isFilterAware = this.$target.hasClass('filter-aware');
    this.filterOptions = [];
    if (this.persistenceEnabled) {
      this.tableDefaults = {
        stateSave: true,
        stateDuration: Number.MAX_SAFE_INTEGER,
        stateLoadCallback: this.getTransformedStateData,
        buttons: [
          {
            extend: 'colvis',
            columns: ':not(.show-always)',
          },
        ],
      };
      this.stateSaveCallback = this.saveTransformedStateData;
    } else {
      this.stateSaveCallback = () => {};
    }

    const throttledSearch = _.debounce((keyUpEvent) => {
      const $searchInput = $(keyUpEvent.currentTarget);
      if (this.table !== null) {
        const keyup = keyUpEvent.keyCode || keyUpEvent.charCode;

        if (!isValidKeyup(keyup)) {
          return false;
        }

        const minChars = $searchInput.attr('data-search-min')
          ? $searchInput.attr('data-search-min')
          : 0;
        if ($searchInput.val().length >= minChars || $searchInput.val() === '') {
          this.table.search($searchInput.val()).draw();
        }
      }
    }, 400);

    this.$target.closest('.section').on('keyup', '.custom-table-search', throttledSearch);
  }

  init(self) {
    self.init_func(self);
    self.table
      .on('column-visibility.dt', () => {
        self.stateSaveCallback(self.table.state());
        self.verticalCentreColVisButton();
      })
      .on('order.dt', () => {
        if (!self.initialOrderingDone) {
          // do not persist the state on initial ordering
          self.initialOrderingDone = true;
          return;
        }
        // the order event does not seem to trigger an update to state - do it manually
        self.table.state.save();
        self.stateSaveCallback(self.table.state());
      });
  }

  /* eslint-disable quote-props */
  replaceDefaultColVisButton() {
    if (this.persistenceEnabled) {
      this.$colVisButtonContainer = $('<div>', {
        class: 'colvis-button-container',
      });
      const dtButtonContainer = this.table.buttons().container();
      const $colVisButton = $(dtButtonContainer).children('.buttons-colvis').first();

      this.$colVisButtonContainer.append(
        $colVisButton,
        $('<i>', {
          class: 'icon icon-settings colvis-trigger',
          title: 'Add/Remove Columns',
          on: {
            click: (e) => {
              $(e.currentTarget).prev().trigger('click');
            },
          },
        }).tooltipster({
          // todo: update to ToolTipster 4.x and change option name to 'side', offsetX to 'distance'; see: https://github.com/iamceege/tooltipster/issues/566
          offsetX: 5,
          offsetY: 0,
          position: 'left',
          multiple: false,
          functionReady: (origin, tooltip) => {
            tooltip.addClass('tooltipster-colvis');
          },
        }),
      );
      this.$target.parent().prepend(this.$colVisButtonContainer);
      this.verticalCentreColVisButton();
    }
    /* Centring the colvis button in the header fails if the table is not visible
     * Listen for the tab switch event and centre it
     * It is only necessary to do this once
     */
    const currentTab = this.$target.closest('div[role="tabpanel"]').attr('id');
    const $tabList = this.$target.closest('div.wrapper > div.section').find('ul[role="tablist"]');
    const $inactiveTabs = $tabList.find('li:not(.active) > a[data-toggle="tab"]');
    $inactiveTabs.on('shown.bs.tab', (e) => {
      const $activatedTab = $(e.currentTarget);
      if (currentTab === $activatedTab.attr('href').replace('#', '')) {
        if ($activatedTab.data('tab-shown')) {
          return;
        }
        this.verticalCentreColVisButton();
        $activatedTab.data('tab-shown', true);
      }
    });
  }

  verticalCentreColVisButton() {
    const headingRowHeight = this.$target.find('thead th:last-child').outerHeight();
    this.$colVisButtonContainer.css('top', (headingRowHeight - 25) / 2);
  }

  getTableDefaults() {
    return this.tableDefaults;
  }

  toggleTableDisplay(immediate = true) {
    const $targetWrapper = this.$target.closest('.dataTables_wrapper');

    if (this.data.length === 0) {
      if (this.$emptyPlaceholder.length > 0 && this.tableShowing === true) {
        if (immediate) {
          $targetWrapper.hide();
          this.$emptyPlaceholder.show();
        } else {
          $targetWrapper.fadeOut(() => {
            this.$emptyPlaceholder.fadeIn();
          });
        }
        this.tableShowing = false;
      } else if (this.$emptyPlaceholder.length === 0 && this.tableShowing === true) {
        $targetWrapper.show();
        this.tableShowing = true;
      }
    } else if (this.$emptyPlaceholder.length > 0 && this.tableShowing === false) {
      if (immediate) {
        $targetWrapper.show();
        this.$emptyPlaceholder.hide();
      } else {
        this.$emptyPlaceholder.fadeOut(() => {
          $targetWrapper.fadeIn();
        });
      }
      this.tableShowing = true;
    }
  }

  getSelectedIds() {
    const selected = _.filter(this.data, (obj) => obj.selected === true);
    return _.pluck(selected, 'id');
  }

  getTransformedStateData(settings, cb) {
    if (typeof cb !== 'function') {
      throw new Error('You must supply a callback function');
    }
    const $dt = settings.oInstance;
    const expandStateData = (data) => {
      const expanded = {};
      const invertColVis = data.inv || false;
      expanded.order = data.o;
      expanded.search = data.s || {};
      expanded.time = data.t;
      expanded.columns = {};
      settings.aoColumns.forEach((col) => {
        if (data.c.indexOf(col.name) !== -1) {
          expanded.columns[col.name] = !invertColVis;
        } else {
          expanded.columns[col.name] = invertColVis;
        }
      });
      return expanded;
    };
    const columnIndicesByName = {};
    settings.aoColumns.forEach((col, c) => {
      columnIndicesByName[col.name] = c;
    });
    const dtEncodedState = $dt.data('view-state');
    if (dtEncodedState) {
      const dtState = atob(dtEncodedState);
      let dtStateWithColNames = JSON.parse(dtState);

      if (typeof dtStateWithColNames.c !== 'undefined') {
        dtStateWithColNames = expandStateData(dtStateWithColNames);
      }

      const indexedColumns = [];
      const indexedOrder = [];
      // transform the columns back into indexed array
      _.keys(columnIndicesByName).forEach((colName) => {
        const colIndex = columnIndicesByName[colName];
        if (dtStateWithColNames.columns.hasOwnProperty(colName)) {
          indexedColumns[colIndex] = {
            visible: dtStateWithColNames.columns[colName],
          };
        } else {
          // Default to showing columns not in returned data
          indexedColumns[colIndex] = { visible: true };
        }
        if (dtStateWithColNames.order && dtStateWithColNames.order.hasOwnProperty(colName)) {
          indexedOrder[0] = [colIndex, dtStateWithColNames.order[colName]];
        }
      });

      const transformedState = {
        columns: indexedColumns,
        time: dtStateWithColNames.time,
      };
      if (this.reapplySearch) {
        transformedState.search = dtStateWithColNames.search;
      }
      if (indexedOrder.length !== 0) {
        transformedState.order = indexedOrder;
      }

      return cb(transformedState);
    }
    return cb({
      /* default view */
    });
  }

  saveTransformedStateData(data) {
    const table = this.tableName;
    const settings = this.table.settings()[0];

    if (!table) {
      throw new Error('Table name must be set on DataTable element');
    }
    const transformed = {
      columns: {},
      order: {},
    };
    data.columns.forEach((col, c) => {
      const colName = settings.aoColumns[c].name;
      if (typeof colName === 'undefined') {
        throw new Error(
          `To use data persistence, all columns must have a name attribute (column with index ${c} does not)`,
        );
      }
      transformed.columns[colName] = col.visible;
      // order: [ index, asc/desc ]
      // assume only one order column
      if (data.order.length !== 0 && data.order[0][0] === c) {
        transformed.order[colName] = data.order[0][1];
      }
    });
    transformed.search = data.search;
    transformed.time = data.time;

    const persistRoute = Routing.generate('app_setting_datatable_state', {
      table,
    });
    const persistRequest = new E1Request(persistRoute, 'POST', {
      state_data: JSON.stringify(transformed),
    });
    persistRequest.submit(
      () => {},
      () => {
        throw new Error('Failed to save DataTable state');
      },
    );
  }

  getFilterOptions() {
    return this.filterOptions;
  }

  setFilterOptions(data) {
    this.filterOptions = data;
  }
}
