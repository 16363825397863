import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';

class CallToActionWidget {
  constructor($target) {
    this.$target = $target;
    this.slug = this.$target.attr('data-slug');
    this.rfq_id = this.$target.attr('data-rfq-id');
    this.rfq_hash = this.$target.attr('data-rfq-hash');
    this.type = this.$target.attr('data-cta-type');

    this.$target.on('click', '.dismiss', () => {
      this.dismiss();
    });

    this.$target.on('click', '.create-e1-account-trigger', () => {
      this.createAccount();
    });
    this.addShowEvent();
  }

  dismiss() {
    const route = Routing.generate('itp_cta_dismiss', {
      slug: this.slug,
      rfq_id: this.rfq_id,
      rfq_hash: this.rfq_hash,
    });
    const req = new E1Request(route, 'POST', { dismiss: true, cta_type: this.type });
    req.submit();

    if (!this.$target.hasClass('cta-modal')) {
      this.$target.remove();
    }
  }

  createAccount() {
    const route = Routing.generate('itp_cta_signup', {
      slug: this.slug,
      rfq_id: this.rfq_id,
      rfq_hash: this.rfq_hash,
      signup_form: 'cta',
    });
    const req = new E1Request(route, 'GET', {});
    req.show_loading_modal = true;
    req.submit();
  }

  addShowEvent() {
    const route = Routing.generate('itp_cta_addshowevent', {
      slug: this.slug,
      rfq_id: this.rfq_id,
      rfq_hash: this.rfq_hash,
    });
    const req = new E1Request(route, 'POST', { cta_type: this.type });
    req.submit();
  }
}

$(() => {
  const $ctaContainer = $('.cta-container');
  const $modalTrigger = $('.show-pre-existing-modal');

  if ($ctaContainer.length > 0) {
    new CallToActionWidget($ctaContainer);
  }

  if ($modalTrigger.length > 0) {
    const slug = $modalTrigger.attr('data-slug');
    const rfqId = $modalTrigger.attr('data-rfq-id');
    const rfqHash = $modalTrigger.attr('data-rfq-hash');
    const route = Routing.generate('itp_cta_existing', {
      slug,
      rfq_id: rfqId,
      rfq_hash: rfqHash,
    });
    const req = new E1Request(route, 'GET');
    req.show_loading_modal = true;
    req.submit();
  }

  $(document).on('mfpOpen', () => {
    const container = $.magnificPopup.instance.contentContainer;
    const $modal = $(container);
    const $modalCtaContainer = $modal.find('.cta-container-modal');
    if ($modalCtaContainer.length > 0) {
      new CallToActionWidget($modalCtaContainer);
    }
  });
});

$(() => {
  $('a.create-account-view-docs-trigger').on('click', (e) => {
    const $trigger = $(e.currentTarget);
    const slug = $trigger.attr('data-slug');
    const rfqId = $trigger.attr('data-rfq-id');
    const rfqHash = $trigger.attr('data-rfq-hash');
    const route = Routing.generate('itp_cta_signup', {
      slug,
      rfq_id: rfqId,
      rfq_hash: rfqHash,
      signup_form: 'view-docs',
    });
    const req = new E1Request(route, 'GET', {});
    req.show_loading_modal = true;
    req.submit();
  });

  $(document).on('openSignUpModal', (e) => {
    const $trigger = $('a.create-account-view-docs-trigger');
    const slug = $trigger.attr('data-slug');
    const rfqId = $trigger.attr('data-rfq-id');
    const rfqHash = $trigger.attr('data-rfq-hash');
    const route = Routing.generate('itp_cta_signup', {
      slug,
      rfq_id: rfqId,
      rfq_hash: rfqHash,
      signup_form: 'quote-submission-hook',
    });
    const req = new E1Request(route, 'GET', {});
    req.show_loading_modal = true;
    req.submit();
  });
});

$(() => {
  window.addEventListener(
    'itp_cta_signup_from_react_banner',
    ({ detail: { slug, rfqId, rfqHash } }) =>
      new E1Request(
        Routing.generate('itp_cta_signup', {
          slug,
          rfq_id: rfqId,
          rfq_hash: rfqHash,
          signup_form: 'www-itp-banner',
        }),
      )
        .setShowLoadingModal()
        .submit(),
  );
});
