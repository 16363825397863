import $ from 'jquery';
import moment from 'moment-timezone';
import { updateTender } from '../app/stage_matrix_details';
import { getUserTimezone } from '../app/timezone';

$.fn.constraintAwareDatePicker = function (opts) {
  const options = $.extend(opts, {
    autoclose: true,
    useCurrent: false,
  });
  this.datepicker(options).on('changeDate', (e) => {
    const $picker = $(e.currentTarget);
    const constraintTargetAttribute = 'constrains-id';
    const constraintTypeAttribute = 'constraint-type';
    const constraintTargetId = $picker.data(constraintTargetAttribute);
    if (constraintTargetId) {
      const $updateTarget = $(`#${constraintTargetId}`);

      const selectedDate = $picker.datepicker('getDate');
      const selectedDateMoment = moment(selectedDate);
      const dateFormat = $picker.data('date-format').toUpperCase();
      const constraintType = $picker.data(constraintTypeAttribute);

      const targetDateMoment = moment($updateTarget.datepicker('getDate'));

      let method = null;
      let beforeAfterDate = null;

      switch (constraintType) {
        case 'gt':
        case 'gte':
          method = 'setStartDate';
          beforeAfterDate =
            constraintType === 'gt' ? selectedDateMoment.add(1, 'd') : selectedDateMoment;
          if (selectedDateMoment.isAfter(targetDateMoment)) {
            $updateTarget.datepicker('clearDates');
          }
          break;
        case 'lt':
        case 'lte':
          method = 'setEndDate';
          beforeAfterDate =
            constraintType === 'lt' ? selectedDateMoment.sub(1, 'd') : selectedDateMoment;
          if (selectedDateMoment.isBefore(targetDateMoment)) {
            $updateTarget.datepicker('clearDates');
          }
          break;
        default:
          throw new Error('You must specify a constraint type');
      }
      $updateTarget.datepicker(method, beforeAfterDate.format(dateFormat));
    }
  });
  return this;
};

export function getAllDateClasses() {
  return {
    today: 'date_today',
    tomorrow: 'date_tomorrow',
    past: 'date_past',
    future: 'date_future',
  };
}

export function initQuotesDueDatePicker($inputs) {
  $inputs.each((i, input) => {
    const customFormat = $(input).data('date-format');
    $(input).datepicker({
      todayHighlight: true,
      format: {
        toDisplay(date) {
          return TimezoneAwareDateHelpers.getDateStringTodayTomorrow(date, customFormat, true);
        },
        toValue(date) {
          return TimezoneAwareDateHelpers.getMomentFromDateString(date, customFormat).toDate();
        },
      },
    });
  });

  $inputs
    .on('changeDate', (e) => {
      const $elem = $(e.currentTarget);
      const stageId = $elem.attr('data-stage-id');
      const dateString = $elem.datepicker('getDate');
      const dueDate = moment(dateString).local();
      if (dueDate.isValid()) {
        const data = {};
        data[$elem.attr('name')] = dueDate.format('YYYY-MM-DD');
        updateTender(stageId, data);
      } else {
        $elem.datepicker('setDate', $elem.attr('data-fallback'));
      }
    })
    .off('focus');
}

export const TimezoneAwareDateHelpers = {
  outputDateFormat: 'ddd, MMM D',
  strToday: 'Today',
  strTomorrow: 'Tomorrow',
  getDateStringTodayTomorrow(date, dateFormat, omitYearIfCurrentYear, dateFormatIfCurrentYear) {
    if (!moment(date).isValid()) {
      return '';
    }
    const currentTzTime = moment.tz(getUserTimezone());
    const comparisonDate = moment.tz(date, getUserTimezone());
    if (currentTzTime.isSame(comparisonDate, 'day')) {
      return this.strToday;
    }
    currentTzTime.add(1, 'days');
    if (currentTzTime.isSame(comparisonDate, 'day')) {
      return this.strTomorrow;
    }

    dateFormat =
      omitYearIfCurrentYear && comparisonDate.isSame(moment(), 'year')
        ? dateFormatIfCurrentYear || dateFormat.replace(/Y/g, '')
        : dateFormat;

    return moment(date).format(dateFormat || this.outputDateFormat);
  },
  getMomentFromDateString(dateStr, format) {
    const userTimezoneMidnight = moment.tz(getUserTimezone()).startOf('day');
    switch (dateStr) {
      case this.strToday:
        return userTimezoneMidnight;
      case this.strTomorrow:
        userTimezoneMidnight.add(1, 'days');
        return userTimezoneMidnight;
      default:
        return moment.tz(dateStr, format || this.outputDateFormat, getUserTimezone());
    }
  },
  getQuotesDueClassFromDate(date) {
    const dateRelative = this.getDateStringTodayTomorrow(date);
    const dateClasses = getAllDateClasses();
    let dateClass = dateClasses[dateRelative.toLowerCase()] || null;

    if (!dateClass) {
      dateClass = this._getDaysDiff(date) > 0 ? dateClasses.future : dateClasses.past;
    }
    return dateClass;
  },
  getInlineQuoteDateSpan(stringOrMoment) {
    const dueDate = typeof stringOrMoment === 'string' ? stringOrMoment : stringOrMoment.format();
    const $dateSpan = $('<span>');
    $dateSpan
      .addClass(this.getQuotesDueClassFromDate(dueDate).replace('date_', 'dt-'))
      .text(this.getDateStringTodayTomorrow(dueDate, "MMM D [']YY", true, 'MMM D'));

    return $dateSpan;
  },
  _getDaysDiff(comparison, current) {
    current = current || moment().tz(getUserTimezone());
    const comparisonDate =
      typeof comparison === 'string' ? new moment.tz(comparison, getUserTimezone()) : comparison;

    return comparisonDate.diff(current, 'days');
  },
  _test(range) {
    range = range || 6;
    const ref = moment().utc().startOf('day');
    let dueDate;
    let dateString;
    ref.subtract(Math.floor(range / 2), 'days');
    while (range--) {
      dueDate = ref.format();
      dateString = TimezoneAwareDateHelpers.getDateStringTodayTomorrow(dueDate);
      console.log(`Input String:         ${dueDate}`);
      console.log(`Relative Date Format: ${dateString}`);
      console.log(
        `Relative Date Class:  ${TimezoneAwareDateHelpers.getQuotesDueClassFromDate(dueDate)}`,
      );
      console.log(
        `Inline Span HTML:     ${$('<i>')
          .append(TimezoneAwareDateHelpers.getInlineQuoteDateSpan(dueDate))
          .html()}`,
      );
      console.log('-------------');
      ref.add(1, 'days');
    }
  },
};
