import $ from 'jquery';
import Routing from 'routing';
import moment from 'moment-timezone';
import E1Request from '../classes/E1Request';

function CurrentDocList($target, init_func, fetchUrl) {
  this.$target = $target;
  this.$container = $target.closest('.current-doc-table');
  this.$emptyPlaceholder = this.$container.find('.empty-text-placeholder');
  this.data = [];
  this.table = null;

  this.tableShowing = true;
  this.init_func = init_func;
  this.fetchUrl = fetchUrl;

  const self = this;
  self.fetch(self.init_func);
}

CurrentDocList.prototype.drawTable = function () {
  const self = this;
  self.toggleTableDisplay();

  if (self.table != null) {
    self.table.rows().remove();
    self.table.rows.add(self.data).draw();
  }
};

CurrentDocList.prototype.fetch = async function (callback) {
  const self = this;
  const response = await new E1Request(self.fetchUrl, 'GET').submit();

  self.data = (response.data || []).map((document) => {
    const data = document;
    data.DT_RowId = `dt_data_${data.id}`;
    data.directoryName = document.directoryName || '';

    return data;
  });

  if (typeof callback === 'function') {
    callback(self);
  }
};

CurrentDocList.prototype.updateTable = function (fetch, cb) {
  const self = this;
  if (fetch) {
    self.fetch($.proxy(self.drawTable, self));
  } else {
    self.drawTable();
  }
  if (typeof cb !== 'undefined') {
    cb();
  }
};

CurrentDocList.prototype.getData = function () {
  return this.data;
};

CurrentDocList.prototype.toggleTableDisplay = function (immediate) {
  const self = this;

  if (immediate == null) {
    immediate = true;
  }

  if (self.data.length == 0) {
    if (self.$emptyPlaceholder.length > 0 && self.tableShowing == true) {
      if (immediate) {
        self.$target.hide();
        self.$emptyPlaceholder.show();
      } else {
        self.$target.fadeOut(() => {
          self.$emptyPlaceholder.fadeIn();
        });
      }
      self.tableShowing = false;
    } else if (self.$emptyPlaceholder.length == 0 && self.tableShowing == true) {
      self.$target.show();

      self.tableShowing = true;
    }
  } else if (self.$emptyPlaceholder.length > 0 && self.tableShowing == false) {
    if (immediate) {
      self.$target.show();
      self.$emptyPlaceholder.hide();
    } else {
      self.$emptyPlaceholder.fadeOut(() => {
        self.$target.fadeIn();
      });
    }
    self.tableShowing = true;
  }
};

$(() => {
  const $currentDocumentTable = $('table.current-document-table');

  $currentDocumentTable.each(function () {
    const $target = $(this);
    const slug = $target.attr('data-slug');
    const rfqId = $target.attr('data-rfq-id');
    const rfqHash = $target.attr('data-rfq-hash');
    const rfqTimezone = $target.attr('data-rfq-timezone');

    const route = Routing.generate('itp_slugrfq_current_documents', {
      slug,
      rfq_id: rfqId,
      rfq_hash: rfqHash,
    });

    const table = new CurrentDocList(
      $target,
      (list) => {
        list.table = list.$target.DataTable({
          paging: false,
          data: list.data,
          autoWidth: false,
          info: false,
          order: [[0, 'asc']],
          columns: [
            {
              data: 'directoryName',
              render: (data) => $.fn.dataTable.render.text(data || ''),
              width: '0',
            },
            {
              data: 'directoryName',
              className: 'grouping-col',
              width: '5%',
              render: (data) => `<div>${data}</div>`,
            },
            {
              data: 'drawingId',
              render: $.fn.dataTable.render.text(),
              width: '15%',
            },
            {
              data: 'title',
              className: 'title-col',
              render: $.fn.dataTable.render.text(),
            },
            {
              data: 'revision',
              render: $.fn.dataTable.render.text(),
              width: '15%',
            },
            {
              data: 'updatedAt',
              width: '15%',
              render(data, type) {
                const date = moment.utc(data.date);
                date.tz(rfqTimezone);

                if (type === 'sort') {
                  return date.format('X');
                }

                const $lastUpdate = $('<span>');
                $lastUpdate.text(date.format('DD/MM/YYYY'));

                return $lastUpdate.prop('outerHTML');
              },
            },
          ],
          dom: 'Rrtp',
        });

        list.$target.rowGrouping({
          iGroupingColumnIndex: 0,
          bExpandableGrouping: true,
          bSetGroupingClassOnTR: true,
          fnOnGroupCompleted(group) {
            const $targetCell = $(group.nGroup).find('td:first');

            const $folderIcon = $('<i />').addClass('glyphicon folder-indicator');
            $targetCell.prepend($folderIcon);
          },
        });

        list.$target.closest('.loading-container').addClass('has-loaded');
        list.table.columns.adjust().draw();
      },
      route,
    );
  });
});
