import $ from 'jquery';
import Form from '../classes/Form';

$('document').ready(() => {
  $('body').on('submit', 'form.magic-form', (e) => {
    const magicForm = new Form($(e.currentTarget));
    magicForm.submit();
    return false;
  });

  $('body').on('click', 'button[type=submit]', (e) => {
    $('button[type=submit]', $(e.currentTarget).closest('form')).removeAttr('clicked');
    $(e.currentTarget).attr('clicked', 'true');
  });
});
