import $ from 'jquery';
import Form from '../classes/Form';

if ($('.unsub-main').length > 0) {
  $(() => {
    $('body').on('submit', 'form.unsub-form', function (e) {
      e.preventDefault();

      const $form = $(this);
      const magicForm = new Form($form);

      magicForm.submit();
      return false;
    });

    $(document).on('change', '.radio', function (e) {
      const _radio_value = $(this).val();
      toggleMarkOptions(_radio_value);
    });

    function toggleMarkOptions(value) {
      if (value == '1') {
        $('#unsubscribe_not_quoting_0').prop('disabled', false);
      } else {
        $('#unsubscribe_not_quoting_0').prop('disabled', 'disabled');
        $('#unsubscribe_not_quoting_0').prop('checked', false);
      }
    }
  });
}
