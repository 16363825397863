import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';
import Modal from '../classes/Modal';
import { clientDeviceIsMobileOrTablet, openDocumentInNewTab } from './doc_viewer';

export function SearchModule($formCtn, $modal) {
  this.$formContainer = $formCtn;
  this.$form = this.$formContainer.find('form.form-preview-search');
  this.$searchInput = this.$form.find('input[name="query"]');
  this.$modal = $modal;
  this.docTree = null;
  this.pdfViewer = null;
  this.modal = null;
  this.$backButton = this.$formContainer.find('.back-to-results');
  this.currentSearch = null;
  this.pendingSearch = null;
  this.results = [];

  const self = this;

  self.$form.on('submit', () => self.$searchInput.val().length !== 0);

  self.$form.on('form-submitted-success', (evt, response) => {
    if (response.success) {
      self.currentSearch = response.query;
      self.results = response.results;
      self.paintModal();
      self.updateForm();
    }
  });

  self.$backButton.on('click', () => {
    self.showModal();
  });

  $('body').on('click', 'a.doc-search-trigger', (onSearchResultClick) => {
    onSearchResultClick.preventDefault();
    const docId = $(onSearchResultClick.currentTarget).attr('data-document-id');

    if (clientDeviceIsMobileOrTablet()) {
      openDocumentInNewTab(self.$form.attr('data-stage-id'), docId);
      return false;
    }

    if (self.modal !== null && typeof self.modal.close !== 'undefined') {
      self.modal.close();
    }

    if (self.pdfViewer) {
      self.pdfViewer.loadDocument(docId);
    }

    if (self.docTree) {
      self.docTree.focusDoc(docId);
    }

    self.pendingSearch = self.currentSearch;
    return false;
  });

  window.addEventListener('documentload', () => {
    if (self.hasPendingSearch()) {
      if (self.pdfViewer) {
        self.pdfViewer.searchDocument(self.pendingSearch);
      }
      self.pendingSearch = null;
    }
  });
}

SearchModule.prototype = {
  setPdfViewer(pdfViewer) {
    this.pdfViewer = pdfViewer;
  },
  setDocTree(docTree) {
    this.docTree = docTree;
  },
  hasPendingSearch() {
    const self = this;
    return self.pendingSearch !== null;
  },
  paintModal() {
    const self = this;
    self.$modal.find('.result-count').text(self.results.length);
    self.$modal.find('.search-term').text(self.currentSearch);

    const $resultList = self.$modal.find('.result-list');
    $resultList.find('li').not('.placeholder').remove();

    $.each(self.results, (i, doc) => {
      const $e = $resultList.find('.placeholder').clone();
      $e.removeClass('placeholder').removeClass('hide');

      const $a = $e.find('a');
      const { id, title, directoryName, drawingId, revision, highlights } = doc;

      $a.text(title);
      $a.highlight(self.currentSearch);
      $a.attr('data-document-id', id);

      $e.find('.doc-folder').text(directoryName || '(no folder)');
      $e.find('.doc-details .doc-drawing-number').text(drawingId).highlight(self.currentSearch);
      $e.find('.doc-details .doc-drawing-revision').text(revision ? `Revision ${revision}` : '');

      if (typeof highlights !== 'undefined') {
        $e.find('.text').text(highlights[0]).highlight(self.currentSearch);
      } else {
        $e.find('.text').remove();
      }
      $resultList.append($e);
    });

    self.showModal();
  },
  showModal() {
    const self = this;
    self.modal = new Modal(self.$modal.prop('outerHTML'));
    self.modal.show();
  },
  updateForm() {
    const self = this;
    self.$backButton.find('span').text(self.results.length);
    if (self.results.length > 0) {
      self.$backButton.removeClass('collapse');
    } else {
      self.$backButton.addClass('collapse');
    }
  },
};

export function HistoryWidget($target) {
  this.$target = $target;
  this.$loadedCtn = this.$target.find('.loaded');
  this.$loadingCtn = this.$target.find('.loading');
  this.request = new E1Request('', 'GET', null, true);
  this.docTree = null;
  this.pdfViewer = null;

  const self = this;
  self.initialize();
}

HistoryWidget.prototype = {
  initialize() {
    const self = this;
    self.request.extraCallback = (response) => {
      if (response.history_html != null) {
        self.$loadedCtn.html(response.history_html);
        self.$loadingCtn.addClass('hide');
        self.$loadedCtn.removeClass('hide');
      }
    };

    $('body').on('click', 'a.doc-history-trigger', (onDocHistoryClick) => {
      onDocHistoryClick.preventDefault();
      const docId = $(onDocHistoryClick.currentTarget).attr('data-document-id');

      if (self.pdfViewer) {
        self.pdfViewer.loadDocument(docId);
      }

      if (self.docTree) {
        self.docTree.focusDoc(docId);
      }

      return false;
    });
  },
  setPdfViewer(pdfViewer) {
    this.pdfViewer = pdfViewer;
  },
  setDocTree(docTree) {
    this.docTree = docTree;
  },
  updateDetails(stageId, docId) {
    const self = this;
    self.request.url = Routing.generate('app_document_history', {
      id: docId,
    });
  },
  reload() {
    const self = this;
    self.$loadingCtn.removeClass('hide');
    self.$loadedCtn.addClass('hide');

    self.request.submit();
  },
};

export function DetailsWidget($target) {
  this.$target = $target;
  this.$loadedCtn = this.$target.find('.loaded');
  this.$loadingCtn = this.$target.find('.loading');
  this.request = new E1Request('', 'GET', null, true);
  this.editMode = 0;

  const self = this;
  self.initialize();
}

DetailsWidget.prototype = {
  initialize() {
    const self = this;
    self.request.extraCallback = (response) => {
      if (response.details_html != null) {
        self.$loadedCtn.html(response.details_html);
        self.$loadingCtn.addClass('hide');
        self.$loadedCtn.removeClass('hide');
      }
    };

    self.$target.on('click', '.edit-toggle', () => {
      self.editMode = 1;
      self.reload();
    });
  },
  updateDetails(stageId, docId) {
    const self = this;
    self.request.url = Routing.generate('app_document_details', {
      id: docId,
    });
    self.editMode = 0;
  },
  reload() {
    const self = this;
    self.$loadingCtn.removeClass('hide');
    self.$loadedCtn.addClass('hide');

    self.request.data = {
      edit: self.editMode,
    };
    self.request.submit();
  },
};

export function PackagesWidget($target) {
  this.$target = $target;
  this.$loadedCtn = this.$target.find('.loaded');
  this.$loadingCtn = this.$target.find('.loading');
  this.shouldRequest = true;
  this.request = new E1Request('', 'GET', null, true);
  this.editable = false;
  const self = this;
  self.initialize();
}

PackagesWidget.prototype = {
  initialize() {
    const self = this;
    self.request.extraCallback = (response) => {
      if (response.packages_html != null) {
        self.$loadedCtn.html(response.packages_html);
        self.$loadingCtn.addClass('hide');
        self.$loadedCtn.removeClass('hide');

        self.afterRepaint();
      }
    };

    self.$target.on('click', '.discipline-expand', (onDisciplineExpand) => {
      const $disc = $(onDisciplineExpand.currentTarget).closest('div.discipline');
      $disc.toggleClass('expanded');
    });

    self.$target.on('click', '.discipline input.select-all', (onDisciplineSelectAll) => {
      const $selectAllInput = $(onDisciplineSelectAll.currentTarget);
      const $disc = $selectAllInput.closest('div.discipline');
      $disc.find('input[type=checkbox]').prop('checked', $selectAllInput.prop('checked'));
    });

    self.$target.on('click', '.discipline .packages input', (onDisciplinePackageClick) => {
      const $disc = $(onDisciplinePackageClick.currentTarget).closest('div.discipline');
      self.repaintDisciplineSection($disc);
    });
  },
  afterRepaint() {
    const self = this;
    self.$target
      .find('div.discipline')
      .toArray()
      .forEach((disc) => {
        self.repaintDisciplineSection($(disc));
      });
  },
  repaintDisciplineSection($container) {
    const $selectAllCheck = $container.find('input.select-all');
    const $packagesCtn = $container.find('.packages');
    const $unselectedPackages = $packagesCtn.find('input:checkbox:not(:checked)');

    const $unselectedPackageSize = $unselectedPackages.length;

    let isChecked = $unselectedPackageSize === 0;
    let isIndeterminate = false;

    if ($unselectedPackageSize > 0) {
      const $selectedPackages = $packagesCtn.find('input:checkbox:checked');
      isChecked = false;
      isIndeterminate = $selectedPackages.length > 0;
    }
    $selectAllCheck.prop('checked', isChecked).prop('indeterminate', isIndeterminate);
  },
  updateDetails(stageId, docId) {
    const self = this;
    self.request.url = Routing.generate('app_document_packages', {
      id: docId,
      edit: self.editable,
    });
  },
  reload() {
    const self = this;
    self.$loadingCtn.removeClass('hide');
    self.$loadedCtn.addClass('hide');

    if (self.shouldRequest) {
      self.request.submit(null, () => {
        self.showAccessDenied();
      });
    } else {
      self.showAccessDenied();
    }
  },
  showAccessDenied() {
    const self = this;
    const $accessDeniedMessage = $('<p />').text(self.$loadedCtn.attr('data-access-denied'));
    self.$loadedCtn.html($accessDeniedMessage.prop('outerHTML'));
    self.$loadingCtn.addClass('hide');
    self.$loadedCtn.removeClass('hide');
  },
};

export function SupersededByModule($target) {
  this.$target = $target;
  this.request = new E1Request('', 'GET', null, true);
  this.docTree = null;
  this.pdfViewer = null;

  const self = this;
  self.initialize();
}

SupersededByModule.prototype = {
  initialize() {
    const self = this;
    self.request.extraCallback = (response) => {
      if (response.supersededBy != null) {
        const supersededBy = JSON.parse(response.supersededBy);
        const link = self.$target.find('.superseded-link');

        link.attr('data-doc-id', supersededBy.id).text(supersededBy.title_with_details);

        self.$target.show();
      } else {
        self.$target.hide();
      }
    };

    $('body').on('click', 'a.superseded-link', (onSupersededClick) => {
      onSupersededClick.preventDefault();
      const $supersededLink = $(onSupersededClick.currentTarget);
      const docId = $supersededLink.attr('data-doc-id');

      if (self.pdfViewer) {
        self.pdfViewer.loadDocument(docId);
      }

      if (self.docTree) {
        self.docTree.focusDoc(docId);
      }

      return false;
    });
  },
  setPdfViewer(pdfViewer) {
    this.pdfViewer = pdfViewer;
  },
  setDocTree(docTree) {
    this.docTree = docTree;
  },
  updateDetails(stageId, docId) {
    const self = this;
    self.request.url = Routing.generate('app_document_supersededby', {
      id: docId,
    });
  },
  reload() {
    const self = this;
    self.request.submit();
    self.$target.hide();
  },
};
