var NullCharactersRegExp = /\x00/g;

/**
 * View History - This is a utility for saving various view parameters for
 *                recently opened files.
 *
 * The way that the view parameters are stored depends on how PDF.js is built,
 * for 'node make <flag>' the following cases exist:
 *  - FIREFOX or MOZCENTRAL - uses sessionStorage.
 *  - B2G                   - uses asyncStorage.
 *  - GENERIC or CHROME     - uses localStorage, if it is available.
 */
var ViewHistory = (function ViewHistoryClosure() {
    function ViewHistory(fingerprint) {
        this.fingerprint = fingerprint;
        this.isInitializedPromiseResolved = false;
        this.initializedPromise =
            this._readFromStorage().then(function (databaseStr) {
                this.isInitializedPromiseResolved = true;

                var database = JSON.parse(databaseStr || '{}');
                if (!('files' in database)) {
                    database.files = [];
                }
                if (database.files.length >= VIEW_HISTORY_MEMORY) {
                    database.files.shift();
                }
                var index;
                for (var i = 0, length = database.files.length; i < length; i++) {
                    var branch = database.files[i];
                    if (branch.fingerprint === this.fingerprint) {
                        index = i;
                        break;
                    }
                }
                if (typeof index !== 'number') {
                    index = database.files.push({fingerprint: this.fingerprint}) - 1;
                }
                this.file = database.files[index];
                this.database = database;
            }.bind(this));
    }

    ViewHistory.prototype = {
        _writeToStorage: function ViewHistory_writeToStorage() {
            return new Promise(function (resolve) {
                var databaseStr = JSON.stringify(this.database);



                localStorage.setItem('database', databaseStr);
                resolve();
            }.bind(this));
        },

        _readFromStorage: function ViewHistory_readFromStorage() {
            return new Promise(function (resolve) {


                resolve(localStorage.getItem('database'));
            });
        },

        set: function ViewHistory_set(name, val) {
            if (!this.isInitializedPromiseResolved) {
                return;
            }
            this.file[name] = val;
            return this._writeToStorage();
        },

        setMultiple: function ViewHistory_setMultiple(properties) {
            if (!this.isInitializedPromiseResolved) {
                return;
            }
            for (var name in properties) {
                this.file[name] = properties[name];
            }
            return this._writeToStorage();
        },

        get: function ViewHistory_get(name, defaultValue) {
            if (!this.isInitializedPromiseResolved) {
                return defaultValue;
            }
            return this.file[name] || defaultValue;
        }
    };

    return ViewHistory;
})();


function removeNullCharacters(str) {
    return str.replace(NullCharactersRegExp, '');
}

function getFileName(url) {
    var anchor = url.indexOf('#');
    var query = url.indexOf('?');
    var end = Math.min(
        anchor > 0 ? anchor : url.length,
        query > 0 ? query : url.length);
    return url.substring(url.lastIndexOf('/', end) + 1, end);
}

/**
 * Returns scale factor for the canvas. It makes sense for the HiDPI displays.
 * @return {Object} The object with horizontal (sx) and vertical (sy)
 scales. The scaled property is set to false if scaling is
 not required, true otherwise.
 */
export function getOutputScale(ctx) {
    var devicePixelRatio = window.devicePixelRatio || 1;
    var backingStoreRatio = ctx.webkitBackingStorePixelRatio ||
        ctx.mozBackingStorePixelRatio ||
        ctx.msBackingStorePixelRatio ||
        ctx.oBackingStorePixelRatio ||
        ctx.backingStorePixelRatio || 1;
    var pixelRatio = devicePixelRatio / backingStoreRatio;
    return {
        sx: pixelRatio,
        sy: pixelRatio,
        scaled: pixelRatio !== 1
    };
}

/**
 * Scrolls specified element into view of its parent.
 * @param {Object} element - The element to be visible.
 * @param {Object} spot - An object with optional top and left properties,
 *   specifying the offset from the top left edge.
 * @param {boolean} skipOverflowHiddenElements - Ignore elements that have
 *   the CSS rule `overflow: hidden;` set. The default is false.
 */
export function scrollIntoView(element, spot, skipOverflowHiddenElements) {
    // Assuming offsetParent is available (it's not available when viewer is in
    // hidden iframe or object). We have to scroll: if the offsetParent is not set
    // producing the error. See also animationStartedClosure.
    var parent = element.offsetParent;
    if (!parent) {
        console.error('offsetParent is not set -- cannot scroll');
        return;
    }
    var checkOverflow = skipOverflowHiddenElements || false;
    var offsetY = element.offsetTop + element.clientTop;
    var offsetX = element.offsetLeft + element.clientLeft;
    while (parent.clientHeight === parent.scrollHeight ||
    (checkOverflow && getComputedStyle(parent).overflow === 'hidden')) {
        if (parent.dataset._scaleY) {
            offsetY /= parent.dataset._scaleY;
            offsetX /= parent.dataset._scaleX;
        }
        offsetY += parent.offsetTop;
        offsetX += parent.offsetLeft;
        parent = parent.offsetParent;
        if (!parent) {
            return; // no need to scroll
        }
    }
    if (spot) {
        if (spot.top !== undefined) {
            offsetY += spot.top;
        }
        if (spot.left !== undefined) {
            offsetX += spot.left;
            parent.scrollLeft = offsetX;
        }
    }
    parent.scrollTop = offsetY;
}

/**
 * Helper function to start monitoring the scroll event and converting them into
 * PDF.js friendly one: with scroll debounce and scroll direction.
 */
export function watchScroll(viewAreaElement, callback) {
    var debounceScroll = function debounceScroll(evt) {
        if (rAF) {
            return;
        }
        // schedule an invocation of scroll for next animation frame.
        rAF = window.requestAnimationFrame(function viewAreaElementScrolled() {
            rAF = null;

            var currentY = viewAreaElement.scrollTop;
            var lastY = state.lastY;
            if (currentY !== lastY) {
                state.down = currentY > lastY;
            }
            state.lastY = currentY;
            callback(state);
        });
    };

    var state = {
        down: true,
        lastY: viewAreaElement.scrollTop,
        _eventHandler: debounceScroll
    };

    var rAF = null;
    viewAreaElement.addEventListener('scroll', debounceScroll, true);
    return state;
}

/**
 * Helper function to parse query string (e.g. ?param1=value&parm2=...).
 */
function parseQueryString(query) {
    var parts = query.split('&');
    var params = {};
    for (var i = 0, ii = parts.length; i < ii; ++i) {
        var param = parts[i].split('=');
        var key = param[0].toLowerCase();
        var value = param.length > 1 ? param[1] : null;
        params[decodeURIComponent(key)] = decodeURIComponent(value);
    }
    return params;
}

/**
 * Use binary search to find the index of the first item in a given array which
 * passes a given condition. The items are expected to be sorted in the sense
 * that if the condition is true for one item in the array, then it is also true
 * for all following items.
 *
 * @returns {Number} Index of the first array element to pass the test,
 *                   or |items.length| if no such element exists.
 */
function binarySearchFirstItem(items, condition) {
    var minIndex = 0;
    var maxIndex = items.length - 1;

    if (items.length === 0 || !condition(items[maxIndex])) {
        return items.length;
    }
    if (condition(items[minIndex])) {
        return minIndex;
    }

    while (minIndex < maxIndex) {
        var currentIndex = (minIndex + maxIndex) >> 1;
        var currentItem = items[currentIndex];
        if (condition(currentItem)) {
            maxIndex = currentIndex;
        } else {
            minIndex = currentIndex + 1;
        }
    }
    return minIndex; /* === maxIndex */
}

/**
 *  Approximates float number as a fraction using Farey sequence (max order
 *  of 8).
 *  @param {number} x - Positive float number.
 *  @returns {Array} Estimated fraction: the first array item is a numerator,
 *                   the second one is a denominator.
 */
function approximateFraction(x) {
    // Fast paths for int numbers or their inversions.
    if (Math.floor(x) === x) {
        return [x, 1];
    }
    var xinv = 1 / x;
    var limit = 8;
    if (xinv > limit) {
        return [1, limit];
    } else  if (Math.floor(xinv) === xinv) {
        return [1, xinv];
    }

    var x_ = x > 1 ? xinv : x;
    // a/b and c/d are neighbours in Farey sequence.
    var a = 0, b = 1, c = 1, d = 1;
    // Limiting search to order 8.
    while (true) {
        // Generating next term in sequence (order of q).
        var p = a + c, q = b + d;
        if (q > limit) {
            break;
        }
        if (x_ <= p / q) {
            c = p; d = q;
        } else {
            a = p; b = q;
        }
    }
    // Select closest of the neighbours to x.
    if (x_ - a / b < c / d - x_) {
        return x_ === x ? [a, b] : [b, a];
    } else {
        return x_ === x ? [c, d] : [d, c];
    }
}

function roundToDivide(x, div) {
    var r = x % div;
    return r === 0 ? x : Math.round(x - r + div);
}

/**
 * Generic helper to find out what elements are visible within a scroll pane.
 */
export function getVisibleElements(scrollEl, views, sortByVisibility) {
    var top = scrollEl.scrollTop, bottom = top + scrollEl.clientHeight;
    var left = scrollEl.scrollLeft, right = left + scrollEl.clientWidth;

    function isElementBottomBelowViewTop(view) {
        var element = view.div;
        var elementBottom =
            element.offsetTop + element.clientTop + element.clientHeight;
        return elementBottom > top;
    }

    var visible = [], view, element;
    var currentHeight, viewHeight, hiddenHeight, percentHeight;
    var currentWidth, viewWidth;
    var firstVisibleElementInd = (views.length === 0) ? 0 :
        binarySearchFirstItem(views, isElementBottomBelowViewTop);

    for (var i = firstVisibleElementInd, ii = views.length; i < ii; i++) {
        view = views[i];
        element = view.div;
        currentHeight = element.offsetTop + element.clientTop;
        viewHeight = element.clientHeight;

        if (currentHeight > bottom) {
            break;
        }

        currentWidth = element.offsetLeft + element.clientLeft;
        viewWidth = element.clientWidth;
        if (currentWidth + viewWidth < left || currentWidth > right) {
            continue;
        }
        hiddenHeight = Math.max(0, top - currentHeight) +
            Math.max(0, currentHeight + viewHeight - bottom);
        percentHeight = ((viewHeight - hiddenHeight) * 100 / viewHeight) | 0;

        visible.push({
            id: view.id,
            x: currentWidth,
            y: currentHeight,
            view: view,
            percent: percentHeight
        });
    }

    var first = visible[0];
    var last = visible[visible.length - 1];

    if (sortByVisibility) {
        visible.sort(function(a, b) {
            var pc = a.percent - b.percent;
            if (Math.abs(pc) > 0.001) {
                return -pc;
            }
            return a.id - b.id; // ensure stability
        });
    }
    return {first: first, last: last, views: visible};
}

/**
 * Event handler to suppress context menu.
 */
function noContextMenuHandler(e) {
    e.preventDefault();
}

/**
 * Returns the filename or guessed filename from the url (see issue 3455).
 * url {String} The original PDF location.
 * @return {String} Guessed PDF file name.
 */
export function getPDFFileNameFromURL(url) {
    var reURI = /^(?:([^:]+:)?\/\/[^\/]+)?([^?#]*)(\?[^#]*)?(#.*)?$/;
    //            SCHEME      HOST         1.PATH  2.QUERY   3.REF
    // Pattern to get last matching NAME.pdf
    var reFilename = /[^\/?#=]+\.pdf\b(?!.*\.pdf\b)/i;
    var splitURI = reURI.exec(url);
    var suggestedFilename = reFilename.exec(splitURI[1]) ||
        reFilename.exec(splitURI[2]) ||
        reFilename.exec(splitURI[3]);
    if (suggestedFilename) {
        suggestedFilename = suggestedFilename[0];
        if (suggestedFilename.indexOf('%') !== -1) {
            // URL-encoded %2Fpath%2Fto%2Ffile.pdf should be file.pdf
            try {
                suggestedFilename =
                    reFilename.exec(decodeURIComponent(suggestedFilename))[0];
            } catch(e) { // Possible (extremely rare) errors:
                // URIError "Malformed URI", e.g. for "%AA.pdf"
                // TypeError "null has no properties", e.g. for "%2F.pdf"
            }
        }
    }
    return suggestedFilename || 'document.pdf';
}
