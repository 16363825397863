import { captureMessage } from '@sentry/browser';
import E1Request, { E1Response } from './E1Request';

export default class ModalLink {
  private readonly route: string | null;

  private readonly method: 'GET' | 'POST' | 'PUT';

  private showLoading: boolean;

  constructor(private $link: JQuery) {
    this.route = this.$link.data('route') || null;
    this.method = this.$link.data('method') || 'GET';
    this.showLoading = this.$link.data('show-loading') !== undefined;
  }

  public async submit<
    TRes extends E1Response,
    TReq extends Record<string, unknown>,
  >(): Promise<TRes | void> {
    const errors = this.getValidationErrors();

    if (errors.length) {
      captureMessage(
        `The following errors were found whilst submitting a ModalLink: ${errors.join(',')}`,
      );
      return undefined;
    }

    if (!this.route) {
      throw new Error('Cannot call modal link - route not defined');
    }

    return new E1Request<TRes, TReq>(this.route, this.method)
      .setShowLoadingModal(this.showLoading)
      .submit();
  }

  public getValidationErrors(): string[] {
    const errors = [];

    if (this.route === null) {
      errors.push('Route must be set');
    }

    if (!ModalLink.getValidMethods().includes(this.method)) {
      errors.push(`Provided method ${this.method} is not valid`);
    }

    return errors;
  }

  private static getValidMethods(): string[] {
    return ['GET', 'POST', 'PUT'];
  }
}
