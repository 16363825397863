/*
 *  Extend DataTables to allow for Document ID sorting.
 *  Strips non-alphanumeric characters, then splits into sections, then
 *  compares each section on its merits.
 *
 */
import jQuery from 'jquery';

(function () {
  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  function docRefSort(a, b) {
    const sre = /[^0-9a-zA-Z]/gi;
    const x = a.toString().replace(sre, '').toUpperCase() || '';
    const y = b.toString().replace(sre, '').toUpperCase() || '';
    let xD;
    let yD;
    const partsX = x.match(/(\d+|[^\d]+)/g);
    const partsY = y.match(/(\d+|[^\d]+)/g);

    // Ensure we have some data to compare
    if (!partsX) {
      return 1;
    }
    if (!partsY) {
      return -1;
    }

    for (let $i = 0; $i < partsX.length && $i < partsY.length; $i++) {
      if (isNumeric(partsX[$i]) && isNumeric(partsY[$i])) {
        // Both parts numeric
        // 01 == 1, 2 == 2, 003 = 3, etc
        xD = parseInt(partsX[$i]);
        yD = parseInt(partsY[$i]);

        if (xD > yD) {
          return 1;
        }
        if (yD > xD) {
          return -1;
        }
      } else if (partsX[$i] != partsY[$i]) {
        // One or both are non-numeric, and they're different. Do a normal evaluation.
        return partsX[$i] > partsY[$i] ? -1 : 1;
      }
    }

    // Common parts are identical, but one may have more parts than the other.
    if (partsX.length != partsY.length) {
      return partsX.length > partsY.length ? 1 : -1;
    }

    // Same number of parts, all identical.
    return 0;
  }

  jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    'docref-asc': function (a, b) {
      return docRefSort(a, b);
    },

    'docref-desc': function (a, b) {
      return docRefSort(a, b) * -1;
    },
  });
})();
