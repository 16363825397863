import $ from 'jquery';
import Routing from 'routing';
import _ from 'underscore';
import E1Request from '../classes/E1Request';
import jstree from '../../plugins/jstree/jstree';
import { subtractFromDocsDownloadedCount } from './doc_viewer';

function TreeFile(id, name, path) {
  this.id = id;
  this.name = name;
  this.path = path;
}

TreeFile.prototype = {
  getTreeId() {
    return `file-${this.id}`;
  },
  get text() {
    const $link = $('<a />');
    $link
      .attr('role', 'button')
      .attr('data-document-id', this.id)
      .attr('title', this.name)
      .attr('data-path', this.path)
      .addClass('doc-viewer-toggle')
      .text(this.name);
    return $link.prop('outerHTML');
  },
  getJson() {
    return {
      text: this.text,
      id: this.getTreeId(),
      a_attr: {
        class: 'file-anchor',
      },
      data: this.id,
      checked: false,
      selected: false,
    };
  },
};

function TreeFolder(name, path) {
  this.name = name;
  this.path = path;
  this.children = {};
  this.files = [];
}

TreeFolder.prototype = {
  getTreeId() {
    return `folder-${this.path}-${this.name}`;
  },
  getSubFolder(name) {
    return this.children[name];
  },
  addSubFolder(name) {
    this.children[name] = new TreeFolder(name, `${this.path}-${this.name}`);
    return this.children[name];
  },
  addFile(file) {
    this.files.push(file);
  },
  get text() {
    const $link = $('<a />');
    $link
      .attr('role', 'button')
      .attr('title', this.name)
      .attr('data-path', this.path)
      .addClass('doc-folder-toggle')
      .text(this.name);
    return $link.prop('outerHTML');
  },
  getJson() {
    const subJson = [];
    for (const child in this.children) {
      if (this.children.hasOwnProperty(child)) {
        const childFolder = this.children[child];
        subJson.push(childFolder.getJson());
      }
    }
    this.files.forEach((file) => {
      subJson.push(file.getJson());
    });
    return {
      text: this.text,
      id: this.getTreeId(),
      a_attr: {
        class: 'folder-anchor',
      },
      children: subJson,
      data: null,
      checked: false,
      selected: false,
    };
  },
};

function JsonTree() {
  this.base = new TreeFolder('-', '');
}

JsonTree.prototype = {
  addDocObj(doc) {
    const file = new TreeFile(doc.id, doc.name);
    const folderList = doc.getFolderList();
    let jsonFolder = this.base;
    folderList.forEach((folderName) => {
      let child = jsonFolder.getSubFolder(folderName);
      if (child == null) {
        child = jsonFolder.addSubFolder(folderName);
      }
      jsonFolder = child;
    });

    jsonFolder.addFile(file);
  },
  getJson() {
    const subJson = [];
    for (const child in this.base.children) {
      if (this.base.children.hasOwnProperty(child)) {
        const childFolder = this.base.children[child];
        subJson.push(childFolder.getJson());
      }
    }
    this.base.files.forEach((file) => {
      subJson.push(file.getJson());
    });
    return subJson;
  },
};

function TreeDocument(doc) {
  this._obj = doc;
}

TreeDocument.prototype = {
  getId() {
    return this._obj.id;
  },
  _getFolderString() {
    const directory = this._obj.pendingRevision
      ? this._obj.pendingRevision.newDirectoryName
      : this._obj.directoryName;

    return directory || '-';
  },
  getFolderList() {
    return this._getFolderString().split('/');
  },
  get id() {
    return this._obj.id;
  },
  get name() {
    return this._obj.fileName;
  },
};

export function TreeViewer($container) {
  this.$container = $container;
  this.$target = this.$container.find('.loadedContainer');
  this.stageId = $container.attr('data-stage-id');
  this.$parent = this.$target.closest('.doc-tree-col');
  this.$optionsCtn = this.$parent.find('.options-container');
  this.$helperCtn = this.$parent.find('.options-helper-container');
  this.stageType = $container.attr('data-stage-type');
  this.rfqId = $container.attr('data-rfq-id') ? $container.attr('data-rfq-id') : false;
  this.isRfqContext = !!this.rfqId;
  this.$transmittalToggle = $container.find('input.transmittal_docs_toggle');
  this.docRequest = null;
  this.docArray = [];
  this.tree = new JsonTree();
  this.jsTree = null;

  this.initialize();

  this.$target.on('click', '.doc-folder-toggle', (e) => {
    const $node = $(e.currentTarget).closest('.jstree-node');
    const nodeId = $node.attr('id');
    this.$target.jstree('toggle_node', nodeId);
    return false;
  });

  this.$target.on('ready.jstree', (e, data) => {
    this.$target.trigger('doc-tree-loaded');
  });

  this.$target.on('check_node.jstree', (e, data) => {
    this.updateOptionsCtn();
  });

  this.$target.on('uncheck_node.jstree', (e, data) => {
    this.updateOptionsCtn();
  });

  this.$target.on('click', '.folder-anchor', () => {
    // there is a 50ms timeout on jstree when cascading checking status from parent to child
    // we need to set a timeout to avoid prematurally trying to count the checked options.
    setTimeout(() => {
      this.updateOptionsCtn();
    }, 150);
  });

  this.$optionsCtn.on('click', '.btn-download', () => {
    const docIds = this.getSelectedIds();
    if (docIds.length > 0) {
      const route = this.getDownloadRoute();
      const data = { docId: docIds };
      const request = new E1Request(route, 'POST', data);
      request.extraCallback = function (resp) {
        if (typeof resp.downloadUrl !== 'undefined') {
          window.location = resp.downloadUrl;
        }

        if (typeof resp.numMissingDocsFound !== 'undefined') {
          subtractFromDocsDownloadedCount(resp.numMissingDocsFound);
        }
      };
      request.submit();
    }
  });

  this.$transmittalToggle.on('change', () => {
    this.docRequest.url = this.getRoute();
    this.loadDocuments();
  });
}

TreeViewer.prototype = {
  initialize() {
    const route = this.getRoute();
    this.docRequest = new E1Request(route);
    this.loadDocuments();
    this.setHeight();
    $(window).on('resize', () => {
      this.setHeight();
    });
  },
  getRoute() {
    let route = null;
    const transmittal = this.$transmittalToggle.is(':checked') ? 1 : 0;
    if (this.isRfqContext) {
      route = Routing.generate('app_tenderstagedocumentviewer_docs', {
        id: this.stageId,
        rfqId: this.rfqId,
      });
    } else {
      route = Routing.generate('app_stagedocument_approved', {
        id: this.stageId,
        transmittal,
      });
    }
    return route;
  },
  getDownloadRoute() {
    let route = null;
    if (this.isRfqContext) {
      route = Routing.generate('app_rfq_viewerdownload', {
        id: this.rfqId,
      });
    } else {
      route = Routing.generate('app_stagedocument_download', {
        id: this.stageId,
      });
    }
    return route;
  },
  setHeight() {
    const offsets = this.$target.offset().top + this.$optionsCtn.outerHeight();

    const height = this.$parent.height() - offsets;
    this.$target.height(height);
  },
  parseDocumentsIntoJsonTree() {
    this.tree = new JsonTree();
    this.docArray.forEach((doc) => {
      const docObj = new TreeDocument(doc);
      this.tree.addDocObj(docObj);
    });
  },
  focusDoc(docId) {
    this.$target.find('li.active-select').removeClass('active-select');

    const node = this.$target.jstree('get_node', `file-${docId}`);
    this.$target.jstree('_open_to', `file-${docId}`);
    this.$target
      .find(`a#file-${docId}_anchor`)
      .find('a.doc-viewer-toggle')
      .closest('li')
      .addClass('active-select');
  },
  loadDocuments() {
    this.docRequest.extraCallback = (response) => {
      if (response.data) {
        this.docArray = response.data;
        this.parseDocumentsIntoJsonTree();
        this.buildTree();
      }
    };
    this.docRequest.submit();
  },
  getSelectedIds() {
    const checked = this.$target.jstree('get_checked', true);
    return _.reduce(
      checked,
      (acc, obj) => {
        if (obj.data != null) {
          acc.push(obj.data);
        }
        return acc;
      },
      [],
    );
  },
  buildTree() {
    this.jsTree = this.$target.jstree({
      core: {
        data: this.tree.getJson(),
        themes: {
          dots: false,
          icons: false,
        },
      },
      checkbox: {
        tie_selection: false,
        whole_node: false,
      },
      plugins: ['checkbox'],
    });
  },
  updateOptionsCtn() {
    const count = this.getSelectedIds().length;
    this.$optionsCtn.find('.selected-count').text(count);
    if (count > 0) {
      this.$optionsCtn.show();
      this.$helperCtn.hide();
    } else {
      this.$optionsCtn.hide();
      this.$helperCtn.show();
    }
  },
};
