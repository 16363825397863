export type DataTableRenderType = 'display' | 'sort' | 'filter' | 'type';
export type RenderFunction<T, R = string> = (
  data: T,
  cellMetadata: DataTables.CellMetaSettings,
  type: DataTableRenderType,
) => R;

type DataTableRenderFunction<T, R = string> = (
  _: unknown,
  type: DataTableRenderType,
  data: T,
  cellMetadata: DataTables.CellMetaSettings,
) => R;

export const pickDataAndCellMetadata =
  <Data, R = string>(renderFunction: RenderFunction<Data, R>): DataTableRenderFunction<Data, R> =>
  (_: unknown, type: DataTableRenderType, data: Data, cellMetadata: DataTables.CellMetaSettings) =>
    renderFunction(data, cellMetadata, type);
