import $ from 'jquery';

const updateSelectAllOption = ($link, groupsHash) => {
  const $group = $link.closest('.select_group');
  const groupId = $group.attr('data-group-id');
  const hashInfo = groupsHash[groupId];

  const selected = $group.find('input:enabled:checkbox:checked');
  if (selected.length === hashInfo.count) {
    $link.attr('data-select', 0);
    $link.text($link.attr('data-alt'));
  } else {
    $link.attr('data-select', 1);
    $link.text($link.attr('data-default'));
  }
};

const getCheckboxHash = ($tradeForm) => {
  const groupsHash = {};
  const $groups = $tradeForm.find('.select_group');

  $groups.toArray().forEach((element) => {
    const $element = $(element);
    const groupId = $element.attr('data-group-id');
    const $checkboxes = $element.find('input:enabled:checkbox');

    groupsHash[groupId] = {
      count: $checkboxes.length,
    };
  });

  return groupsHash;
};

export const monitorTrades = ($tradeContainer) => {
  const groupsHash = getCheckboxHash($tradeContainer);

  $tradeContainer
    .find('.select-all-trades')
    .toArray()
    .forEach((val) => {
      updateSelectAllOption($(val), groupsHash);
    });

  $tradeContainer.on('click', '.select-all-trades', (e) => {
    const $link = $(e.currentTarget);
    const $container = $link.closest('.select_group');

    if (parseInt($link.attr('data-select'), 10) === 1) {
      $container.find('input:enabled:checkbox').prop('checked', true);
    } else {
      $container.find('input:enabled:checkbox').prop('checked', false);
    }
    updateSelectAllOption($link, groupsHash);
  });

  $tradeContainer.on('change', '.select_group input:checkbox', (e) => {
    const $container = $(e.currentTarget).closest('.select_group');
    const $link = $container.find('.select-all-trades');
    updateSelectAllOption($link, groupsHash);
  });
};
