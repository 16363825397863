import $ from 'jquery';
import Routing from 'routing';
import {
  PDFJS,
  getDocument,
  InvalidPDFException,
  MissingPDFException,
  UnexpectedResponseException,
  UNSUPPORTED_FEATURES,
  createBlob,
} from 'pdfjs-dist';
import { PDFPresentationMode } from 'pdfjs-dist/lib/web/pdf_presentation_mode';
import { PDFRenderingQueue } from 'pdfjs-dist/lib/web/pdf_rendering_queue';
import { EventBus } from 'pdfjs-dist/lib/web/ui_utils';
import { PDFJS as PDFJSViewer } from 'pdfjs-dist/web/pdf_viewer';
import 'pdfjs-dist/web/pdf_viewer.css';
import E1Request from '../classes/E1Request';
import PDFFindBar from '../../plugins/pdfjs-viewer/classes/PDFFindBar';
import PDFFindController from '../../plugins/pdfjs-viewer/classes/PDFFindController';
import HandTool from '../../plugins/pdfjs-viewer/hand_tool';
import { getPDFFileNameFromURL } from '../../plugins/pdfjs-viewer/helper';
import Preferences from '../../plugins/pdfjs-viewer/preferences';
import { isInConstructionMode } from '../app/construction_util';
import { DOC_VIEWER_URL_HASH } from '../app/doc_viewer';
import FullscreenLauncher from '../classes/FullscreenLauncher';
import Throttler from '../classes/Throttler';

// load this from CDN where available
// see app/Resources/views/app/_shared/foot.html.twig
PDFJS.workerSrc = window.global.workerSrc;

export function E1PDFViewerLauncher(stageId, extraOptions) {
  this.class = 'dark';
  this.options = {
    id: stageId,
  };
  this.launcher = null;

  const self = this;

  $.extend(self.options, extraOptions);
  self.launcher = new FullscreenLauncher(self.route, self.class);
}

E1PDFViewerLauncher.prototype = {
  trigger() {
    const self = this;

    addOrRemoveMobileViewportAttributes('add');
    window.location.hash = DOC_VIEWER_URL_HASH;
    window.DOC_VIEWER_IS_OPEN = true;
    self.launcher.trigger();
  },
  launchforrfq() {
    const self = this;
    const url = Routing.generate('app_tenderstagedocumentviewer_rfqtransmittal', self.options);
    const request = new E1Request(url, 'POST');
    request.extraCallback = function (resp) {
      if (typeof resp.launch_previewer !== 'undefined') {
        self.trigger();
      }
    };

    request.submit();
  },
  get docId() {
    const self = this;
    return self.options.docId;
  },
  set docId(docId) {
    const self = this;
    self.options.docId = docId;
  },
  get transmittal() {
    const self = this;
    return !!self.options.transmittal;
  },
  set transmittal(transmittal) {
    const self = this;
    self.options.transmittal = transmittal ? 1 : 0;
  },
  get route() {
    const self = this;
    if (isInConstructionMode() || self.options.forceConstructionMode) {
      delete self.options.forceConstructionMode;
      return Routing.generate('app_constructionstagedocumentviewer_index', self.options);
    }
    if (self.options.rfqId) {
      return Routing.generate('app_tenderstagedocumentviewer_rfq', self.options);
    }

    return Routing.generate('app_tenderstagedocumentviewer_doc', self.options);
  },
};

window.closeAllDocViewer = function () {
  FullscreenLauncher.closeAll();
  $('body').trigger('doc-viewer-close');

  if (window.location.hash.indexOf(DOC_VIEWER_URL_HASH) !== -1) {
    // Remove docviewer hash from URL
    window.history.back();
  }
};

export function E1PDFViewer($target) {
  this.$target = $target;
  this.outerContainer = $target.find('#outerContainer').get(0);
  this.url = this.$target.attr('data-url');
  this.application = null;
  this.stageId = $target.attr('data-stage-id');
  this.docRequest = null;
  this.docId = $target.attr('data-document-id');
  this.canEditDocument = !!$target.data('document-editable');
  this.throttler = null;
  this.document = null;

  const self = this;
  self.initialize();
}

E1PDFViewer.prototype = {
  initialize() {
    const self = this;
    self.throttler = new Throttler();
    self.docRequest = new E1Request();
    self.docRequest.extraCallback = function (response) {
      if (response.url) {
        self.url = response.url;
        self.document = JSON.parse(response.document);
        self.refreshApplication();
      }
      if (typeof response.editable !== 'undefined') {
        self.canEditDocument = !!response.editable;
      } else {
        self.canEditDocument = null;
      }
    };

    if (self.outerContainer && self.url) {
      self.application = new PDFViewerApplication($(self.outerContainer));
      self.application.initialize();
      self.application.open(self.url);
      self.triggerDocLoadedEvent();
    }
  },
  loadDocument(docId) {
    const self = this;
    self.docId = docId;
    self.application.setLoading(true);
    self.docRequest.url = Routing.generate('app_stagedocument_url', {
      id: self.stageId,
      docId: self.docId,
    });
    self.throttler.add(() => {
      self.docRequest.submit();
    });
  },
  searchDocument(search) {
    const self = this;
    self.application.findBar.findField.value = search;
    self.application.findBar.dispatchEvent('');
    self.application.findBar.open();
  },
  refreshApplication() {
    this.application.open(this.url);
    this.application.findController.reset();
    if (this.application.findBar.opened) {
      this.application.findBar.dispatchEvent('');
    }
    this.triggerDocLoadedEvent();
  },
  triggerDocLoadedEvent() {
    const self = this;
    self.$target.trigger('doc-loaded', {
      stageId: self.stageId,
      docId: self.docId,
      docEditable: self.canEditDocument,
    });
  },
};

function selectScaleOption(value) {
  let predefinedValueFound = false;
  const $dropDown = $('select#scaleSelect');
  $dropDown
    .find('option')
    .toArray()
    .forEach((o) => {
      const $opt = $(o);
      const match = $opt.val() === value.toString();
      $opt.attr('selected', match);
      if (match) {
        predefinedValueFound = true;
        $dropDown.val($opt.val());
      }
    });
  return predefinedValueFound;
}

const DEFAULT_SCALE_DELTA = 1.1;
const MIN_SCALE = 0.25;
const MAX_SCALE = 10.0;
const PAGE_NUMBER_LOADING_INDICATOR = 'visiblePageIsLoading';

function PDFViewerApplication($container) {
  this.$container = $container;
  this.viewerContainerDiv = this.$container.find('.viewerContainer').get(0);
  this.viewerDiv = $(this.viewerContainerDiv).find('.pdfViewer').get(0);
  this.$toolbarContainer = this.$container.find('.toolbarContainer');
  this.pdfRenderingQueue = null;
  this.pdfLinkService = null;
  this.pdfViewer = null;
  this.pdfDocument = null;
  this.loading = false;
  this.pagesRefMap = {};
  this.mouseScrollTimeStamp = 0;
  this.mouseScrollDelta = 0;
  this.findController = null;
  this.findBar = null;
  this.handTool = null;
  this.presentationMode = null;
  this.url = null;

  this.SCALE_OPTIONS = {
    UNKNOWN: 0,
    DEFAULT: 1,
  };

  this.RenderingStates = {
    INITIAL: 0,
    RUNNING: 1,
    PAUSED: 2,
    FINISHED: 3,
  };

  this.PresentationModeState = {
    UNKNOWN: 0,
    NORMAL: 1,
    CHANGING: 2,
    FULLSCREEN: 3,
  };

  this.initialized = false;

  const self = this;

  setupEventHandlers(self);
}

PDFViewerApplication.prototype = {
  initialize: function pdfViewInitialize() {
    const self = this;

    const pdfRenderingQueue = new PDFRenderingQueue();
    pdfRenderingQueue.onIdle = this.cleanup.bind(this);
    self.pdfRenderingQueue = pdfRenderingQueue;

    self.pdfLinkService = new PDFJSViewer.PDFLinkService();

    self.pdfViewer = new PDFJSViewer.PDFViewer({
      container: self.viewerContainerDiv,
      viewer: self.viewerDiv,
      renderingQueue: self.pdfRenderingQueue,
      linkService: self.pdfLinkService,
    });

    Preferences.initialize();

    self.findController = new PDFFindController({
      pdfViewer: this.pdfViewer,
      integratedFind: this.supportsIntegratedFind,
    });
    self.pdfViewer.setFindController(this.findController);

    self.findBar = new PDFFindBar({
      bar: document.getElementById('findbar'),
      findField: document.getElementById('findInput'),
      highlightAllCheckbox: document.getElementById('findHighlightAll'),
      caseSensitiveCheckbox: document.getElementById('findMatchCase'),
      toggleButton: document.getElementById('findToggleBtn'),
      findMsg: document.getElementById('findMsg'),
      findResultsCount: document.getElementById('findResultsCount'),
      findStatusIcon: document.getElementById('findStatusIcon'),
      findPreviousButton: document.getElementById('findPrevious'),
      findNextButton: document.getElementById('findNext'),
      findController: this.findController,
    });

    self.findController.setFindBar(this.findBar);

    self.handTool = HandTool.initialize({
      container: self.viewerContainerDiv,
      toggleHandTool: self.$container.find('.toggleHandTool').get(0),
    });

    const eventBus = new EventBus();
    self.presentationMode = new PDFPresentationMode({
      container: self.viewerContainerDiv,
      viewer: self.viewerDiv,
      pdfViewer: self.pdfViewer,
      eventBus,
    });

    self.pdfRenderingQueue.setViewer(self.pdfViewer);
    const initializedPromise = Promise.all([
      Preferences.get('enableWebGL').then((value) => {
        PDFJS.disableWebGL = !value;
      }),
      Preferences.get('pdfBugEnabled').then((value) => {
        self.preferencePdfBugEnabled = value;
      }),
      Preferences.get('disableTextLayer').then((value) => {
        if (PDFJS.disableTextLayer === true) {
          return;
        }
        PDFJS.disableTextLayer = value;
      }),
      Preferences.get('disableRange').then((value) => {
        if (PDFJS.disableRange === true) {
          return;
        }
        PDFJS.disableRange = value;
      }),
      Preferences.get('disableAutoFetch').then((value) => {
        PDFJS.disableAutoFetch = value;
      }),
      Preferences.get('disableFontFace').then((value) => {
        if (PDFJS.disableFontFace === true) {
          return;
        }
        PDFJS.disableFontFace = value;
      }),
      Preferences.get('useOnlyCssZoom').then((value) => {
        PDFJS.useOnlyCssZoom = value;
      }),
    ]).catch(() => {});

    return initializedPromise.then(() => {
      PDFViewerApplication.initialized = true;
    });
  },
  open: function pdfViewOpen(url, args) {
    const self = this;
    self.clearError();

    if (this.pdfDocument) {
      // Reload the preferences if a document was previously opened.
      Preferences.reload();
    }

    const scale = 'auto';

    self.url = url;
    const parameters = {
      url: self.url,
    };

    if (args) {
      for (const prop in args) {
        parameters[prop] = args[prop];
      }
    }
    self.setLoading(true);
    self.downloadComplete = false;

    getDocument(parameters).then(
      (pdfDocument) => {
        self.load(pdfDocument, scale);
        self.setLoading(false);
      },
      (exception) => {
        const message = exception && exception.message;
        let loadingErrorMessage = 'An error occurred while loading the PDF.';

        if (exception instanceof InvalidPDFException) {
          // change error message also for other builds
          loadingErrorMessage = 'Invalid or corrupted PDF file.';
        } else if (exception instanceof MissingPDFException) {
          // special message for missing PDF's
          loadingErrorMessage = 'Missing PDF file.';
        } else if (exception instanceof UnexpectedResponseException) {
          loadingErrorMessage = 'Unexpected server response.';
        }

        self.error(loadingErrorMessage, { message, url });
        self.setLoading(false);
      },
    );
  },
  setLoading(isLoading) {
    const self = this;
    self.loading = isLoading;
    if (self.loading) {
      self.$container.addClass('is-loading');
    } else {
      self.$container.removeClass('is-loading');
    }
  },
  load: function pdfViewLoad(pdfDocument, scale) {
    const self = this;
    scale = scale || PDFJSViewer.UNKNOWN_SCALE;

    this.pdfDocument = pdfDocument;

    const downloadedPromise = pdfDocument.getDownloadInfo().then(() => {
      self.downloadComplete = true;
    });

    const pagesCount = pdfDocument.numPages;
    self.$container.find('.numPages').text(`/${pagesCount}`);
    self.$container.find('.pageNumber').attr('max', pagesCount);

    self.pdfLinkService.setDocument(pdfDocument);

    const { pdfViewer } = this;
    pdfViewer.setDocument(pdfDocument);
    // pdfViewer.currentScaleValue = scale;

    const { firstPagePromise } = pdfViewer;
    const { pagesPromise } = pdfViewer;

    self.pageRotation = 0;
    self.isInitialViewSet = false;

    firstPagePromise.then(() => {
      downloadedPromise.then(() => {
        const event = document.createEvent('CustomEvent');
        event.initCustomEvent('documentload', true, true, {});
        window.dispatchEvent(event);
      });
    });

    // Fetch the necessary preference values.
    const showPreviousViewOnLoadPromise = Preferences.get('showPreviousViewOnLoad');
    const defaultZoomValuePromise = Preferences.get('defaultZoomValue');

    Promise.all([firstPagePromise, showPreviousViewOnLoadPromise, defaultZoomValuePromise]).then(
      () => {
        self.setInitialView(scale);
      },
      (reason) => {
        console.log(reason);

        firstPagePromise.then(() => {
          self.setInitialView(scale);
        });
      },
    );

    pagesPromise.then(() => {
      if (self.supportsPrinting) {
        pdfDocument.getJavaScript().then((javaScript) => {
          if (javaScript.length) {
            console.warn('Warning: JavaScript is not supported');
            self.fallback(UNSUPPORTED_FEATURES.javaScript);
          }
          // Hack to support auto printing.
          const regex = /\bprint\s*\(/g;
          for (let i = 0, ii = javaScript.length; i < ii; i++) {
            const js = javaScript[i];
            if (js && regex.test(js)) {
              setTimeout(() => {
                window.print();
              });
              return;
            }
          }
        });
      }
    });
  },
  zoomIn: function pdfViewZoomIn(ticks) {
    const self = this;
    let newScale = self.pdfViewer.currentScale;
    do {
      newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2);
      newScale = Math.ceil(newScale * 10) / 10;
      newScale = Math.min(MAX_SCALE, newScale);
    } while (--ticks && newScale < MAX_SCALE);
    self.setScale(newScale, true);
  },
  zoomOut: function pdfViewZoomOut(ticks) {
    const self = this;
    let newScale = self.pdfViewer.currentScale;
    do {
      newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(2);
      newScale = Math.floor(newScale * 10) / 10;
      newScale = Math.max(MIN_SCALE, newScale);
    } while (--ticks && newScale > MIN_SCALE);
    self.setScale(newScale, true);
  },
  setScale(value, resetAutoSettings) {
    console.log('setScale:: ', value);

    // prevent scaling below 0.3
    // as this causes errors in ie10
    if (value < 0.3) {
      value = 0.3;
    }

    const self = this;
    self.updateScaleControls = !!resetAutoSettings;
    self.pdfViewer.currentScaleValue = value;
    self.updateScaleControls = true;
  },
  getAnchorUrl: function getAnchorUrl(anchor) {
    return anchor;
  },
  cachePageRef(pageNum, pageRef) {
    const self = this;
    const refStr = `${pageRef.num} ${pageRef.gen} R`;
    self.pagesRefMap[refStr] = pageNum;
  },
  setInitialView: function pdfViewSetInitialView(scale) {
    const self = this;
    self.isInitialViewSet = true;

    // When opening a new file (when one is already loaded in the viewer):
    // Reset 'currentPageNumber', since otherwise the page's scale will be wrong
    // if 'currentPageNumber' is larger than the number of pages in the file.
    self.pdfViewer.page = 1;
    self.$container.find('.pageNumber').value = self.pdfViewer.page;
    self.setScale(scale, true);
    self.page = 1;

    if (self.pdfViewer.currentScale === self.SCALE_OPTIONS.UNKNOWN) {
      // Scale was not initialized: invalid bookmark or scale was not specified.
      // Setting the default one.
      self.setScale(self.SCALE_OPTIONS.DEFAULT, true);
    }
    self.pdfViewer.update();
  },
  cleanup: function pdfViewCleanup() {
    const self = this;
    self.pdfViewer.cleanup();
    self.pdfDocument.cleanup();
  },
  update() {
    const self = this;
    if (!self.initialized) {
      return;
    }
    self.pdfViewer.update();
  },
  get currentScaleValue() {
    return this.pdfViewer.currentScaleValue;
  },

  get pagesCount() {
    return this.pdfDocument.numPages;
  },

  set page(val) {
    this.pdfViewer.currentPageNumber = val;
  },

  get page() {
    return this.pdfViewer.currentPageNumber;
  },
  close: function pdfViewClose() {
    const errorWrapper = document.getElementById('errorWrapper');
    if (errorWrapper) {
      errorWrapper.setAttribute('hidden', 'true');
    }

    if (!this.pdfDocument) {
      return;
    }

    this.pdfDocument.destroy();
    this.pdfDocument = null;

    this.pdfLinkService.setDocument(null);
    this.pdfViewer.setDocument(null);

    if (typeof PDFBug !== 'undefined') {
      // eslint-disable-next-line no-undef
      PDFBug.cleanup();
    }
  },
  fallback: function pdfViewFallback() {},
  navigateTo: function pdfViewNavigateTo(dest) {
    const self = this;

    const goToDestination = function (destRef) {
      self.pendingRefStr = null;
      // dest array looks like that: <page-ref> </XYZ|FitXXX> <args..>
      let pageNumber =
        destRef instanceof Object
          ? self.pagesRefMap[`${destRef.num} ${destRef.gen} R`]
          : destRef + 1;
      if (pageNumber) {
        if (pageNumber > self.pagesCount) {
          pageNumber = self.pagesCount;
        }
        self.pdfViewer.scrollPageIntoView({
          pageNumber,
          destArray: dest,
          allowNegativeOffset: false,
        });
      } else {
        self.pdfDocument.getPageIndex(destRef).then((pageIndex) => {
          self.pagesRefMap[`${destRef.num} ${destRef.gen} R`] = pageIndex + 1;
          goToDestination(destRef);
        });
      }
    };

    let destinationPromise;
    if (typeof dest === 'string') {
      destinationPromise = this.pdfDocument.getDestination(dest);
    } else {
      destinationPromise = Promise.resolve(dest);
    }
    destinationPromise.then((destination) => {
      dest = destination;
      if (!(destination instanceof Array)) {
        return; // invalid destination
      }
      goToDestination(destination[0]);
    });
  },
  executeNamedAction: function pdfViewExecuteNamedAction(action) {
    // See PDF reference, table 8.45 - Named action
    switch (action) {
      case 'GoToPage':
        this.$container.find('.pageNumber').trigger('focus');
        break;

      case 'NextPage':
        this.page += 1;
        break;

      case 'PrevPage':
        this.page -= 1;
        break;

      case 'LastPage':
        this.page = this.pagesCount;
        break;

      case 'FirstPage':
        this.page = 1;
        break;

      default:
        break; // No action according to spec
    }
  },
  forceRendering: function pdfViewForceRendering() {
    this.pdfRenderingQueue.printing = this.printing;
    this.pdfRenderingQueue.renderHighestPriority();
  },
  rotatePages: function pdfViewRotatePages(delta) {
    const pageNumber = this.page;

    this.pageRotation = (this.pageRotation + 360 + delta) % 360;
    this.pdfViewer.pagesRotation = this.pageRotation;

    this.forceRendering();

    this.pdfViewer.scrollPageIntoView({
      pageNumber,
      destArray: null,
      allowNegativeOffset: false,
    });
  },
  requestPresentationMode: function pdfViewRequestPresentationMode() {
    const self = this;
    if (!self.presentationMode) {
      return;
    }
    self.presentationMode.request();
  },
  /**
   * @param {number} delta - The delta value from the mouse event.
   */
  scrollPresentationMode: function pdfViewScrollPresentationMode(delta) {
    const self = this;
    if (!self.presentationMode) {
      return;
    }
    this.presentationMode.mouseScroll(delta);
  },
  /**
   * This function flips the page in presentation mode if the user scrolls up
   * or down with large enough motion and prevents page flipping too often.
   *
   * @this {PDFView}
   * @param {number} mouseScrollDelta The delta value from the mouse event.
   */
  mouseScroll: function pdfViewMouseScroll(mouseScrollDelta) {
    const MOUSE_SCROLL_COOLDOWN_TIME = 50;

    const currentTime = new Date().getTime();
    const storedTime = this.mouseScrollTimeStamp;

    // In case one page has already been flipped there is a cooldown time
    // which has to expire before next page can be scrolled on to.
    if (currentTime > storedTime && currentTime - storedTime < MOUSE_SCROLL_COOLDOWN_TIME) {
      return;
    }

    // In case the user decides to scroll to the opposite direction than before
    // clear the accumulated delta.
    if (
      (this.mouseScrollDelta > 0 && mouseScrollDelta < 0) ||
      (this.mouseScrollDelta < 0 && mouseScrollDelta > 0)
    ) {
      this.clearMouseScrollState();
    }

    this.mouseScrollDelta += mouseScrollDelta;

    const PAGE_FLIP_THRESHOLD = 120;
    if (Math.abs(this.mouseScrollDelta) >= PAGE_FLIP_THRESHOLD) {
      const PageFlipDirection = {
        UP: -1,
        DOWN: 1,
      };

      // In presentation mode scroll one page at a time.
      const pageFlipDirection =
        this.mouseScrollDelta > 0 ? PageFlipDirection.UP : PageFlipDirection.DOWN;
      this.clearMouseScrollState();
      const currentPage = this.page;

      // In case we are already on the first or the last page there is no need
      // to do anything.
      if (
        (currentPage === 1 && pageFlipDirection === PageFlipDirection.UP) ||
        (currentPage === this.pagesCount && pageFlipDirection === PageFlipDirection.DOWN)
      ) {
        return;
      }

      this.page += pageFlipDirection;
      this.mouseScrollTimeStamp = currentTime;
    }
  },

  download: function pdfViewDownload() {
    const self = this;
    const url = self.url.split('#')[0];
    const filename = getPDFFileNameFromURL(url);
    const downloadManager = new PDFJSViewer.DownloadManager();
    downloadManager.onerror = function () {
      // This error won't really be helpful because it's likely the
      // fallback won't work either (or is already open).
      self.error('PDF failed to download.', { url });
    };

    function downloadByUrl() {
      downloadManager.downloadUrl(url, filename);
    }

    if (!this.pdfDocument) {
      // the PDF is not ready yet
      downloadByUrl();
      return;
    }

    if (!this.downloadComplete) {
      // the PDF is still downloading
      downloadByUrl();
      return;
    }

    this.pdfDocument
      .getData()
      .then(
        (data) => {
          const blob = createBlob(data, 'application/pdf');
          downloadManager.download(blob, url, filename);
        },
        downloadByUrl, // Error occurred try downloading with just the url.
      )
      .then(null, downloadByUrl);
  },

  /**
   * This function clears the member attributes used with mouse scrolling in
   * presentation mode.
   *
   * @this {PDFView}
   */
  clearMouseScrollState: function pdfViewClearMouseScrollState() {
    this.mouseScrollTimeStamp = 0;
    this.mouseScrollDelta = 0;
  },
  error: function pdfViewError(message, { url }) {
    const self = this;
    const errorWrapper = document.getElementById('errorWrapper');
    const $errorWrapper = $(errorWrapper);
    const $notViewableWrapper = $('#notViewableWrapper');
    const $imageContainer = $('.imageContainer');

    const invalidPdf = message === 'Invalid or corrupted PDF file.';
    const { pathname: filePath } = new URL(url);
    const isImage = hasImageExtension(filePath);

    if (invalidPdf) {
      if (isImage) {
        $imageContainer.removeClass('hide').addClass('is-loading');

        const $image = $imageContainer.find('.doc-previewer-image');
        $image
          .on('load', () => {
            $imageContainer.removeClass('is-loading');
          })
          .attr('src', url);
      } else {
        $notViewableWrapper.removeClass('hide');
      }
    } else {
      const $errorMessage = $errorWrapper.find('.errorMessage').find('p span');
      $imageContainer.addClass('hide');
      $errorMessage.text(message);
      $errorWrapper.removeClass('hide');
    }
    self.$container.addClass('hide');
  },
  clearError() {
    const self = this;
    const errorWrapper = document.getElementById('errorWrapper');
    const $errorWrapper = $(errorWrapper);
    const $notViewableWrapper = $('#notViewableWrapper');
    const $imageContainer = $('#imageContainer');

    $errorWrapper.addClass('hide');
    $notViewableWrapper.addClass('hide');
    $imageContainer.addClass('hide');

    self.$container.removeClass('hide');
  },
};

/**
 * @param {string} path
 * @return {boolean}
 */
const hasImageExtension = (path) =>
  ['jpg', 'jpeg', 'png', 'gif'].reduce((acc, ext) => acc || path.endsWith(`.${ext}`), false);

function setupEventHandlers(pdfAppViewer) {
  function isOptionSelected(className) {
    const $item = pdfAppViewer.$container.find(`option.${className}:selected`);
    return $item.length > 0;
  }

  const clickHandlers = {
    previousPage() {
      pdfAppViewer.page = Math.max(1, pdfAppViewer.page - 1);
    },
    nextPage() {
      pdfAppViewer.page = Math.min(pdfAppViewer.page + 1, pdfAppViewer.pagesCount);
    },
    zoomIn() {
      pdfAppViewer.zoomIn();
    },
    zoomOut() {
      pdfAppViewer.zoomOut();
    },
    pageRotateCw() {
      pdfAppViewer.rotatePages(90);
    },
    pageRotateCcw() {
      pdfAppViewer.rotatePages(-90);
    },
    presentationModeRequest() {
      pdfAppViewer.requestPresentationMode();
    },
    downloadRequest() {
      pdfAppViewer.download();
    },
  };

  Object.keys(clickHandlers).forEach((handlerClass) => {
    const $button = pdfAppViewer.$container.find(`.${handlerClass}`);
    if ($button.length) {
      $button.on('click', clickHandlers[handlerClass]);
    }
  });

  // Add non click handlers
  document.getElementById('docDownloadTrigger').addEventListener('click', () => {
    pdfAppViewer.download();
  });

  // Add non click handlers
  document.getElementById('scaleSelect').addEventListener('change', function () {
    if (this.value === 'custom') {
      return;
    }
    pdfAppViewer.setScale(this.value, false);
  });

  pdfAppViewer.$container.find('.pageNumber').on('change', (e) => {
    const $pageInput = $(e.currentTarget);
    const newPageVal = Math.min(
      Math.abs(Math.trunc($pageInput.val())) || 1,
      pdfAppViewer.pagesCount,
    );

    pdfAppViewer.page = newPageVal;
    $pageInput.val(newPageVal);
  });

  window.addEventListener(
    'pagechange',
    (evt) => {
      const page = evt.pageNumber;
      if (evt.previousPageNumber !== page) {
        pdfAppViewer.$container.find('.pageNumber').val(page);
      }

      // checking if the this.page was called from the updateViewarea function
      if (evt.updateInProgress) {
        return;
      }

      // Avoid scrolling the first page during loading
      if (pdfAppViewer.loading && page === 1) {
        return;
      }
      pdfAppViewer.pdfViewer.scrollPageIntoView({
        pageNumber: page,
        destArray: null,
        allowNegativeOffset: false,
      });
    },
    true,
  );

  window.addEventListener(
    'scalechange',
    (evt) => {
      pdfAppViewer.$container.find('.zoomOut').disabled = evt.scale === MIN_SCALE;
      pdfAppViewer.$container.find('zoomIn').disabled = evt.scale === MAX_SCALE;

      const predefinedValueFound = selectScaleOption(evt.presetValue || evt.scale);
      const $scaleDropdown = pdfAppViewer.$container.find('#scaleSelect');

      if (!predefinedValueFound) {
        const customScale = Math.round(evt.scale * 10000) / 100;

        $scaleDropdown.find('option').attr('selected', false);

        $scaleDropdown
          .val('custom')
          .find('#customScaleOption')
          .text(`${customScale}%`)
          .attr('selected', true);
      }

      if (!pdfAppViewer.initialized) {
        return;
      }

      pdfAppViewer.update();
    },
    true,
  );

  document.addEventListener(
    'pagerendered',
    (evt) => {
      const { pageNumber } = evt.detail;
      const pageIndex = pageNumber - 1;
      const pageView = pdfAppViewer.pdfViewer.getPageView(pageIndex);

      if (pageView.error) {
        pdfAppViewer.error('An error occurred while rendering the page.', pageView.error);
      }

      // If the page is still visible when it has finished rendering,
      // ensure that the page number input loading indicator is hidden.
      if (pageNumber === pdfAppViewer.page) {
        const pageNumberInput = pdfAppViewer.$container.find('.pageNumber');
        pageNumberInput.removeClass(PAGE_NUMBER_LOADING_INDICATOR);
      }
    },
    true,
  );

  window.addEventListener('resize', () => {
    if (
      pdfAppViewer.initialized &&
      (isOptionSelected('pageWidthOption') ||
        isOptionSelected('pageFitOption') ||
        isOptionSelected('pageAutoOption'))
    ) {
      const selectedScale = pdfAppViewer.$container.find('.scaleSelect').val();
      pdfAppViewer.setScale(selectedScale, false);
    }
    pdfAppViewer.update();
  });

  window.addEventListener(
    'updateviewarea',
    () => {
      if (!pdfAppViewer.initialized) {
        return;
      }

      // Show/hide the loading indicator in the page number input element.
      const $pageNumberInput = pdfAppViewer.$container.find('.pageNumber');
      const currentPage = pdfAppViewer.pdfViewer.getPageView(pdfAppViewer.page - 1);

      if (currentPage.renderingState === pdfAppViewer.RenderingStates.FINISHED) {
        $pageNumberInput.removeClass(PAGE_NUMBER_LOADING_INDICATOR);
      } else {
        $pageNumberInput.addClass(PAGE_NUMBER_LOADING_INDICATOR);
      }
    },
    true,
  );

  function handleMouseWheel(evt) {
    const MOUSE_WHEEL_DELTA_FACTOR = 40;
    const ticks =
      evt.type === 'DOMMouseScroll' ? -evt.detail : evt.wheelDelta / MOUSE_WHEEL_DELTA_FACTOR;
    const direction = ticks < 0 ? 'zoomOut' : 'zoomIn';

    if (PDFPresentationMode.active) {
      evt.preventDefault();
      pdfAppViewer.mouseScroll(ticks * MOUSE_WHEEL_DELTA_FACTOR);
    } else if (evt.ctrlKey) {
      // Only zoom the pages, not the entire viewer
      evt.preventDefault();
      pdfAppViewer[direction](Math.abs(ticks));
    }
  }

  window.addEventListener('DOMMouseScroll', handleMouseWheel);
  window.addEventListener('mousewheel', handleMouseWheel);
}

export function addOrRemoveMobileViewportAttributes(action) {
  const mobileViewportContent = ', maximum-scale=1, user-scalable=0';
  const viewport = document.querySelector('meta[name=viewport]');
  const viewportContent = viewport.getAttribute('content');
  const indexOfViewPortContent = viewportContent.indexOf(mobileViewportContent);

  switch (action) {
    case 'add':
      if (indexOfViewPortContent === -1) {
        viewport.setAttribute('content', viewportContent + mobileViewportContent);
      }
      break;
    case 'remove':
      if (indexOfViewPortContent > -1) {
        viewport.setAttribute('content', viewportContent.replace(mobileViewportContent, ''));
      }
      break;
    default:
      throw new Error(`"${action}" is not a supported arguement`);
  }
}
