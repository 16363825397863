import $ from 'jquery';

/**
 * Creates a "search bar" given a set of DOM elements that act as controls
 * for searching or for setting search preferences in the UI. This object
 * also sets up the appropriate events for the controls. Actual searching
 * is done by PDFFindController.
 */
export default class PDFFindBar {
  static get FindStates() {
    return {
      FIND_FOUND: 0,
      FIND_NOTFOUND: 1,
      FIND_WRAPPED: 2,
      FIND_PENDING: 3,
    };
  }

  constructor(options) {
    this.opened = false;
    this.bar = options.bar || null;
    this.toggleButton = options.toggleButton || null;
    this.findField = options.findField || null;
    this.highlightAll = options.highlightAllCheckbox || null;
    this.caseSensitive = options.caseSensitiveCheckbox || null;
    this.findMsg = options.findMsg || null;
    this.findResultsCount = options.findResultsCount || null;
    this.findStatusIcon = options.findStatusIcon || null;
    this.findPreviousButton = options.findPreviousButton || null;
    this.findNextButton = options.findNextButton || null;
    this.findController = options.findController || null;

    if (this.findController === null) {
      throw new Error('PDFFindBar cannot be used without a ' +
        'PDFFindController instance.');
    }

    // Add event listeners to the DOM elements.
    this.toggleButton.addEventListener('click', () => {
      this.toggle();
    });

    $(this.bar).on('click', 'button.toggleFindBar', () => {
      this.toggle();
    });

    this.findField.addEventListener('input', () => {
      this.dispatchEvent('');
    });

    this.bar.addEventListener('keydown', (evt) => {
      switch (evt.keyCode) {
        case 13: // Enter
          if (evt.target === this.findField) {
            this.dispatchEvent('again', evt.shiftKey);
          }
          break;
        case 27: // Escape
          this.close();
          break;
      }
    });

    this.findPreviousButton.addEventListener('click', () => {
      this.dispatchEvent('again', true);
    });

    this.findNextButton.addEventListener('click', () => {
      this.dispatchEvent('again', false);
    });

    this.highlightAll.addEventListener('click', () => {
      this.dispatchEvent('highlightallchange');
    });

    this.caseSensitive.addEventListener('click', () => {
      this.dispatchEvent('casesensitivitychange');
    });

    // hijack {ctrl, cmd}+f
    window.addEventListener('keydown', (event) => {
      if (event.ctrlKey || event.metaKey) {
        switch (String.fromCharCode(event.which).toLowerCase()) {
          case 'f':
            event.preventDefault();
            this.toggle();
            break;
        }
      }
    });
  }

  dispatchEvent(type, findPrev) {
    const event = document.createEvent('CustomEvent');
    event.initCustomEvent(`find${type}`, true, true, {
      query: this.findField.value,
      caseSensitive: this.caseSensitive.checked,
      highlightAll: this.highlightAll.checked,
      findPrevious: findPrev,
    });
    return window.dispatchEvent(event);
  }

  updateUIState(state, previous, matchCount) {
    let notFound = false;
    let findMsg = '';
    let status = '';

    switch (state) {
      case PDFFindBar.FindStates.FIND_FOUND:
        break;

      case PDFFindBar.FindStates.FIND_PENDING:
        status = 'pending';
        break;

      case PDFFindBar.FindStates.FIND_NOTFOUND:
        findMsg = 'Phrase not found';
        notFound = true;
        break;

      case PDFFindBar.FindStates.FIND_WRAPPED:
        if (previous) {
          findMsg = 'Reached top of document, continued from bottom';
        } else {
          findMsg = 'Reached end of document, continued from top';
        }
        break;
    }

    if (notFound) {
      this.findField.classList.add('notFound');
    } else {
      this.findField.classList.remove('notFound');
    }

    this.findField.setAttribute('data-status', status);
    this.findMsg.textContent = findMsg;

    this.updateResultsCount(matchCount);
  }

  updateResultsCount(matchCount) {
    if (!this.findResultsCount) {
      return; // no UI control is provided
    }

    // If there are no matches, hide the counter
    if (!matchCount) {
      this.findResultsCount.classList.add('hidden');
      return;
    }

    // Create the match counter
    this.findResultsCount.textContent = `${matchCount} matches found.`;

    // Show the counter
    this.findResultsCount.classList.remove('hidden');
  }

  open() {
    if (!this.opened) {
      this.opened = true;
      this.toggleButton.classList.add('toggled');
      this.bar.classList.remove('hidden');
    }
    this.findField.select();
    this.findField.focus();
  }

  close() {
    if (!this.opened) {
      return;
    }
    this.opened = false;
    this.toggleButton.classList.remove('toggled');
    this.bar.classList.add('hidden');
    this.findController.active = false;
  }

  toggle() {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }
}
