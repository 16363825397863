import $ from 'jquery';

export default class FullscreenLauncher {
  constructor(route, iframeClass) {
    this.class = iframeClass;
    this.$container = $('<iframe />').addClass('full-screen-frame').attr('allowfullscreen', true);

    if (iframeClass !== null) {
      this.$container.addClass(iframeClass);
    }
    this.route = route;
  }

  static closeAll() {
    $('html').css('overflow-y', 'auto');
    $('body').find('iframe.full-screen-frame').remove();
  }

  trigger() {
    this.$container.attr('src', this.route);

    $('body').append(this.$container);
    $('html').css('overflow-y', 'hidden');
  }
}

window.closeAllFullscreen = FullscreenLauncher.closeAll;
