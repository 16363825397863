import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';
// eslint-disable-next-line import/no-cycle
import matrixInit from '../../plugins/e1-doc-matrix/e1matrixUI';
import Modal from '../classes/Modal';
import { triggerGeneratePackagesModal } from './packages';

export const updateTender = async (id: number, data: { status: string }) => {
  if (parseInt(data.status, 10) === 2) {
    // Display a modal to capture additional information about awarded tenders
    return new E1Request(Routing.generate('app_tenderstage_updateawarded', { id }))
      .setShowLoadingModal()
      .submit();
  }
  return new E1Request(Routing.generate('app_tenderstage_update', { id }), 'POST', data).submit();
};

/**
 * @return {boolean}
 */
export const matrixIsInReadOnlyMode = () => {
  const $gridContainer = $('#gridContainer');

  if ($gridContainer.length < 1) {
    throw new Error("Matrix grid container can't be found.");
  }

  return parseInt($gridContainer.data('readonly'), 10) === 1;
};

export const fetchMatrixDetails = async (generateIfEmpty: boolean, continueEditing: boolean) => {
  const $gridContainer = $('#gridContainer');
  const stageId = parseInt($gridContainer.data('stage'), 10);

  const addendumIdData = $gridContainer.data('addendum');
  const addendumId =
    addendumIdData === '' || typeof addendumIdData === 'undefined' ? null : addendumIdData;

  const readOnly = matrixIsInReadOnlyMode();
  const suppressWarning = parseInt($gridContainer.data('suppress-warning'), 10);
  const stageType = parseInt($gridContainer.data('stage-type'), 10);

  const matrixRoute = Routing.generate('app_stagematrix_details', { id: stageId, addendumId });
  const matrixDetails = await new E1Request<{
    success: boolean;
    packages: { fullSet: boolean }[];
    documents: unknown[];
  }>(matrixRoute).submit();

  if (!readOnly && addendumId === null && !suppressWarning) {
    const addendumWarningRoute = Routing.generate('app_stagematrix_pendingaddendummodal', {
      id: stageId,
    });

    const addendumWarningResp = await new E1Request<{
      success: boolean;
      close_modal_manually: boolean;
    }>(addendumWarningRoute, 'GET', {})
      .setShowLoadingModal(false)
      .submit();
    if (
      addendumWarningResp.close_modal_manually &&
      $('.e1-modal.generate-packages-modal').length < 1
    ) {
      Modal.closeAll();
    }
  }

  if (generateIfEmpty) {
    // Loop over the packages, until we hit the first package that isn't a full set.
    const hasNoPackages = matrixDetails.packages.reduce((acc, { fullSet }) => acc && fullSet, true);

    if (hasNoPackages && !readOnly) {
      await triggerGeneratePackagesModal(stageId);
    }
  }

  const options = {
    target: $gridContainer,
    stageId,
    stageType,
    packages: matrixDetails.packages,
    documents: matrixDetails.documents,
    continueEditing,
  };

  return matrixInit(options);
};
