/* eslint-disable fp/no-mutation */
const navBarWithCalculator = () => {
  const navBar = document.querySelector('.navbar-itp') as HTMLElement;
  const rfqContainer = document.querySelector('.rfq-container') as HTMLElement;
  if (navBar && rfqContainer) {
    navBar.style.width = `${rfqContainer.offsetWidth}px`;
  }
};

document.addEventListener('DOMContentLoaded', () => {
  navBarWithCalculator();
});

window.addEventListener('resize', () => {
  navBarWithCalculator();
});
