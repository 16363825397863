import { captureMessage } from '@sentry/browser';

export default class Location {
  constructor(
    private readonly latitude: number,
    private readonly longitude: number, // eslint-disable-next-line no-empty-function
  ) {}

  static getDistanceUnits() {
    if (!window.global?.user) {
      captureMessage('Could not resolve user for distance units - falling back to km');
      return 'km';
    }
    return window.global.user.display_imperial_units ? 'mi' : 'km';
  }

  static getDistanceInLocalUnits(km: number) {
    if (!window.global?.user) {
      captureMessage('Could not resolve user for distance value calculation - falling back to km');
      return km;
    }
    return window.global.user.display_imperial_units ? this.kmToMiles(km) : km;
  }

  static kmToMiles(km: number) {
    const kilometersPerMile = window.global.display_settings.km_miles_constant || 1.60934;
    return km / kilometersPerMile;
  }

  static getNullLocation() {
    return 'NA';
  }

  static readableDistance(km: number) {
    const noDataPlaceholder = '-';
    if (typeof km === 'undefined' || Number.isNaN(km) || km > 4000) {
      return noDataPlaceholder;
    }

    const units = this.getDistanceUnits();
    const distance = Math.round(this.getDistanceInLocalUnits(km));

    return `${distance || '~1'}${units}`;
  }

  static deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }

  static getDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371; // Radius of the earth in km
    const dLat = Location.deg2rad(lat2 - lat1);
    const dLon = Location.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(Location.deg2rad(lat1)) *
        Math.cos(Location.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in km
  }

  distFromLocLng(latitude: number, longitude: number) {
    return Location.getDistance(this.latitude, this.longitude, latitude, longitude);
  }
}
