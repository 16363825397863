import $ from 'jquery';
import Location from '../classes/Location';

const locAttributes = ['from-lat', 'from-lng', 'to-lat', 'to-lng'] as const;

export default function setLocation($field: JQuery) {
  const latLngValues = locAttributes
    .map((attr) => $field.data(attr) || false)
    .filter(Boolean)
    .map((s) => Number(s));

  if (latLngValues.length !== locAttributes.length) {
    return;
  }

  const [fromLat, fromLng, toLat, toLng] = latLngValues;

  const unformattedDistance = new Location(fromLat, fromLng).distFromLocLng(toLat, toLng);

  $field
    .attr('data-metres', unformattedDistance * 1000)
    .text(Location.readableDistance(unformattedDistance))
    .trigger('location-init');
}

$(() => {
  $('.locator-dist-calc')
    .toArray()
    .forEach((locator) => setLocation($(locator)));
});
