import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';

$(() => {
  const $body = $('body');

  const $rfqContainer = $('.rfq-container');
  if ($rfqContainer.length > 0) {
    $rfqContainer.each((i, container) => {
      const $container = $(container);
      const invitationDropdownClass = '.interest-levels-dropdown.invitation-dropdown';
      const modifyInvitationInterestLevelClass = '.modify-invitation-interest-level';

      // Hide "Quoted" option from subbies. They shouldn't be able to update
      // an invitation to "Quoted". That option is reserved for builders only.
      $(invitationDropdownClass)
        .find(`${modifyInvitationInterestLevelClass}[data-interest-level-status=quoted]`)
        .hide();

      $body.on('click', modifyInvitationInterestLevelClass, (e) => {
        const statusDataAttr = 'data-status';
        const interestLevelStatus = '.invitation-interest-levels-status';

        const $trigger = $(e.currentTarget);
        const rfq_id = $container.data('rfq-id');
        const rfq_hash = $container.data('rfq-hash');
        const stage = $container.data('stage-id');
        const slug = $container.data('slug');

        const $dropdown = $trigger.closest(invitationDropdownClass);
        const quote_status = $trigger.data('interest-level-status');

        // Don't submit request if new status and current status are the same
        if ($dropdown.attr(statusDataAttr) === quote_status) {
          return;
        }

        const url = Routing.generate('itp_slugrfq_changestatus', { slug, rfq_id, rfq_hash });
        const req = new E1Request(url, 'POST', { response: quote_status });

        req.extraCallback = (resp) => {
          if (typeof resp.success !== 'undefined' && resp.success) {
            let statusLabel = '';
            if (quote_status === 'considering') {
              statusLabel = $dropdown.data('interest-levels-default-status');
            } else {
              statusLabel = $trigger.data('interest-level-label');
            }

            $dropdown
              .attr(statusDataAttr, quote_status)
              .find(interestLevelStatus)
              .text(statusLabel);
          }
        };
        req.submit();
      });
    });
  }

  const $rfqInviteContainer = $('.rfq-invite');
  if ($rfqInviteContainer.length > 0) {
    $rfqInviteContainer.each((i, container) => {
      const $container = $(container);
      const stage = $container.attr('data-stage-id');
      const slug = $container.attr('data-slug');

      const processDownload = (downloadUrl) => {
        const downloadRequest = new E1Request(downloadUrl, 'POST', {});
        downloadRequest.show_loading_modal = true;
        downloadRequest.extraCallback = (resp) => {
          if (typeof resp.downloadUrl !== 'undefined') {
            const downloadFrame = document.createElement('iframe');
            downloadFrame.setAttribute('src', resp.downloadUrl);
            downloadFrame.setAttribute('class', 'visually-hidden');
            document.body.appendChild(downloadFrame);
          }
        };
        downloadRequest.submit();
      };

      $body.on('click', '.no-docs-available', (e) => {
        const $btn = $(e.currentTarget);
        const req = new E1Request();

        req.flashNotification({
          type: 'e1-success',
          message: $btn.attr('data-no-docs-message'),
        });
      });

      $body.on('click', '.download-trigger', (e) => {
        const $downloadTrigger = $(e.currentTarget);

        if (!$downloadTrigger.hasClass('no-docs-available')) {
          const downloadUrl = Routing.generate('itp_slugrfq_download', {
            slug,
            rfq_id: $downloadTrigger.attr('data-rfq-id'),
            rfq_hash: $downloadTrigger.attr('data-rfq-hash'),
          });
          processDownload(downloadUrl);
        }
      });

      $body.on('click', '.revision-trigger', (e) => {
        const $revisionTrigger = $(e.currentTarget);
        const downloadUrl = Routing.generate('itp_slugrfq_download', {
          slug,
          rfq_id: $revisionTrigger.attr('data-rfq-id'),
          rfq_hash: $revisionTrigger.attr('data-rfq-hash'),
          downloadFrom: $revisionTrigger.attr('data-last-at'),
          downloadRelative: true,
        });
        processDownload(downloadUrl);
      });

      $body.on('click', '.email-transmittal-trigger', (e) => {
        const $transmittalTrigger = $(e.currentTarget);
        const $loading = $('<i>').addClass(
          'loading-indicator glyphicon glyphicon-refresh glyphicon-spin',
        );
        const url = Routing.generate('itp_slugrfq_correspondenceemail', {
          slug,
          rfq_id: $transmittalTrigger.attr('data-rfq-id'),
          rfq_hash: $transmittalTrigger.attr('data-rfq-hash'),
          corr_item_id: $transmittalTrigger.attr('data-correspondence-item-id'),
        });
        const request = new E1Request(url, 'POST');

        $transmittalTrigger.append($loading);
        $transmittalTrigger.attr('disabled', true);

        request.submit((request, response) => {
          $transmittalTrigger.find('.loading-indicator').remove();
          if (response.success) {
            if (response.message) {
              $transmittalTrigger.text(response.message);
            }
          }
        });
      });
    });
  }
});
