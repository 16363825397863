export const sendSpeciFinderInvitationAnalytics = (rfqId: number, action: string, url?: string) => {
  const keywordMatch =
    $(`#hiddenHasKeywordMatches #hasKeywordMatches-rfq-id-${rfqId}`).val() === 'true';

  window.analyticsService?.addInteractEvent(
    {
      action,
      keywordMatch,
    },
    url ?? '/invitations',
  );
};

export const sendSpeciFinderInvitationSliderButtonAnalytics = (buttonName: string) => {
  window.analyticsService?.addInteractEvent({
    action: 'ButtonClicked',
    buttonName,
    screenName: 'invitations_responses_slider',
  });
};
