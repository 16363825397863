import PDFFindBar from './PDFFindBar';
import { scrollIntoView } from '../helper';

/**
 * Provides "search" or "find" functionality for the PDF.
 * This object actually performs the search for a given string.
 */
export default class PDFFindController {
  constructor(options) {
    this.pdfViewer = options.pdfViewer || null;
    this.integratedFind = options.integratedFind || false;
    this.charactersToNormalize = {
      '\u2018': '\'', // Left single quotation mark
      '\u2019': '\'', // Right single quotation mark
      '\u201A': '\'', // Single low-9 quotation mark
      '\u201B': '\'', // Single high-reversed-9 quotation mark
      '\u201C': '"', // Left double quotation mark
      '\u201D': '"', // Right double quotation mark
      '\u201E': '"', // Double low-9 quotation mark
      '\u201F': '"', // Double high-reversed-9 quotation mark
      '\u00BC': '1/4', // Vulgar fraction one quarter
      '\u00BD': '1/2', // Vulgar fraction one half
      '\u00BE': '3/4', // Vulgar fraction three quarters
    };
    this.findBar = options.findBar || null;

    this.reset();

    // Compile the regular expression for text normalization once.
    const replace = Object.keys(this.charactersToNormalize).join('');
    this.normalizationRegex = new RegExp(`[${replace}]`, 'g');

    const events = [
      'find',
      'findagain',
      'findhighlightallchange',
      'findcasesensitivitychange',
    ];

    this.handleEvent = this.handleEvent.bind(this);

    for (let i = 0, len = events.length; i < len; i += 1) {
      window.addEventListener(events[i], this.handleEvent);
    }
  }

  setFindBar(findBar) {
    this.findBar = findBar;
  }

  reset() {
    this.startedTextExtraction = false;
    this.extractTextPromises = [];
    this.pendingFindMatches = Object.create(null);
    this.active = false; // If active, find results will be highlighted.
    this.pageContents = []; // Stores the text for each page.
    this.pageMatches = [];
    this.matchCount = 0;
    this.selected = { // Currently selected match.
      pageIdx: -1,
      matchIdx: -1,
    };
    this.offset = { // Where the find algorithm currently is in the document.
      pageIdx: null,
      matchIdx: null,
    };
    this.pagesToSearch = null;
    this.resumePageIdx = null;
    this.state = null;
    this.dirtyMatch = false;
    this.findTimeout = null;

    this.firstPagePromise = new Promise((resolve) => {
      this.resolveFirstPage = resolve;
    });
  }

  normalize(text) {
    const self = this;
    return text.replace(this.normalizationRegex, ch => self.charactersToNormalize[ch]);
  }

  calcFindMatch(pageIndex) {
    let pageContent = this.normalize(this.pageContents[pageIndex]);
    let query = this.normalize(this.state.query);
    const caseSensitive = this.state.caseSensitive;
    const queryLen = query.length;

    if (queryLen === 0) {
      // Do nothing: the matches should be wiped out already.
      return;
    }

    if (!caseSensitive) {
      pageContent = pageContent.toLowerCase();
      query = query.toLowerCase();
    }

    const matches = this.getMatchesInPageContent(pageContent, query);

    this.pageMatches[pageIndex] = matches;
    this.updatePage(pageIndex);
    if (this.resumePageIdx === pageIndex) {
      this.resumePageIdx = null;
      this.nextPageMatch();
    }

    // Update the matches count
    if (matches.length > 0) {
      this.matchCount += matches.length;
      this.updateUIResultsCount();
    }
  }

  getMatchesInPageContent(pageContent, query) {
    const matches = [];
    const normalisedQuery = query
      .replace(/([^a-z\d\s])/g, (str) => "\\" + str) // escape non-alphanumeric characters
      .replace(/\s+/g, '\\s+') // allow any number of spaces

    const regex = new RegExp(`${normalisedQuery}`, 'g');
    let match;
    while(match = regex.exec(pageContent)) {
      matches.push(match.index);
    }
    return matches
  }

  extractText() {
    if (this.startedTextExtraction) {
      return;
    }
    this.startedTextExtraction = true;

    this.pageContents = [];
    const extractTextPromisesResolves = [];
    const numPages = this.pdfViewer.pagesCount;
    for (let i = 0; i < numPages; i += 1) {
      this.extractTextPromises.push(new Promise((resolve) => {
        extractTextPromisesResolves.push(resolve);
      }));
    }

    const self = this;
    function extractPageText(pageIndex) {
      self.pdfViewer.getPageTextContent(pageIndex).then(
        (textContent) => {
          const textItems = textContent.items;
          const str = [];

          for (let i = 0, len = textItems.length; i < len; i += 1) {
            str.push(textItems[i].str);
          }

          // Store the pageContent as a string.
          self.pageContents.push(str.join(''));

          extractTextPromisesResolves[pageIndex](pageIndex);
          if ((pageIndex + 1) < self.pdfViewer.pagesCount) {
            extractPageText(pageIndex + 1);
          }
        },
      );
    }
    extractPageText(0);
  }

  handleEvent(e) {
    if (this.state === null || e.type !== 'findagain') {
      this.dirtyMatch = true;
    }
    this.state = e.detail;
    this.updateUIState(PDFFindBar.FindStates.FIND_PENDING);

    this.firstPagePromise.then(() => {
      this.extractText();

      clearTimeout(this.findTimeout);
      if (e.type === 'find') {
        // Only trigger the find action after 250ms of silence.
        this.findTimeout = setTimeout(this.nextMatch.bind(this), 250);
      } else {
        this.nextMatch();
      }
    });
  }

  updatePage(index) {
    if (this.selected.pageIdx === index) {
      // If the page is selected, scroll the page into view, which triggers
      // rendering the page, which adds the textLayer. Once the textLayer is
      // build, it will scroll onto the selected match.
      this.pdfViewer.scrollPageIntoView({
        pageNumber: index + 1,
        destArray: null,
        allowNegativeOffset: false,
      });
    }

    const page = this.pdfViewer.getPageView(index);
    if (page.textLayer) {
      page.textLayer.updateMatches();
    }
  }

  nextMatch() {
    const previous = this.state.findPrevious;
    const currentPageIndex = this.pdfViewer.currentPageNumber - 1;
    const numPages = this.pdfViewer.pagesCount;

    this.active = true;

    if (this.dirtyMatch) {
      // Need to recalculate the matches, reset everything.
      this.dirtyMatch = false;
      this.selected.pageIdx = this.selected.matchIdx = -1;
      this.offset.pageIdx = currentPageIndex;
      this.offset.matchIdx = null;
      this.hadMatch = false;
      this.resumePageIdx = null;
      this.pageMatches = [];
      this.matchCount = 0;
      const self = this;

      for (let i = 0; i < numPages; i += 1) {
        // Wipe out any previous highlighted matches.
        this.updatePage(i);

        // As soon as the text is extracted start finding the matches.
        if (!(i in this.pendingFindMatches)) {
          this.pendingFindMatches[i] = true;
          Promise.resolve(this.extractTextPromises[i]).then((pageIdx) => {
            if (pageIdx !== undefined) {
              delete self.pendingFindMatches[pageIdx];
              self.calcFindMatch(pageIdx);
            }
          });
        }
      }
    }

    // If there's no query there's no point in searching.
    if (this.state.query === '') {
      this.updateUIState(PDFFindBar.FindStates.FIND_FOUND);
      return;
    }

    // If we're waiting on a page, we return since we can't do anything else.
    if (this.resumePageIdx) {
      return;
    }

    const offset = this.offset;
    // Keep track of how many pages we should maximally iterate through.
    this.pagesToSearch = numPages;
    // If there's already a matchIdx that means we are iterating through a
    // page's matches.
    if (offset.matchIdx !== null) {
      const numPageMatches = this.pageMatches[offset.pageIdx].length;
      if ((!previous && offset.matchIdx + 1 < numPageMatches) ||
        (previous && offset.matchIdx > 0)) {
        // The simple case; we just have advance the matchIdx to select
        // the next match on the page.
        this.hadMatch = true;
        offset.matchIdx = (previous ? offset.matchIdx - 1 :
          offset.matchIdx + 1);
        this.updateMatch(true);
        return;
      }
      // We went beyond the current page's matches, so we advance to
      // the next page.
      this.advanceOffsetPage(previous);
    }
    // Start searching through the page.
    this.nextPageMatch();
  }

  matchesReady(matches) {
    const offset = this.offset;
    const numMatches = matches.length;
    const previous = this.state.findPrevious;

    if (numMatches) {
      // There were matches for the page, so initialize the matchIdx.
      this.hadMatch = true;
      offset.matchIdx = (previous ? numMatches - 1 : 0);
      this.updateMatch(true);
      return true;
    }
    // No matches, so attempt to search the next page.
    this.advanceOffsetPage(previous);
    if (offset.wrapped) {
      offset.matchIdx = null;
      if (this.pagesToSearch < 0) {
        // No point in wrapping again, there were no matches.
        this.updateMatch(false);
        // while matches were not found, searching for a page
        // with matches should nevertheless halt.
        return true;
      }
    }
    // Matches were not found (and searching is not done).
    return false;
  }

  /**
   * The method is called back from the text layer when match presentation
   * is updated.
   * @param {number} pageIndex - page index.
   * @param {number} index - match index.
   * @param {Array} elements - text layer div elements array.
   * @param {number} beginIdx - start index of the div array for the match.
   * @param {number} endIdx - end index of the div array for the match.
   */
  updateMatchPosition(pageIndex, index, elements, beginIdx, endIdx) {
    if (this.selected.matchIdx === index && this.selected.pageIdx === pageIndex) {
      const spot = {
        top: -50,
        left: -400,
      };
      scrollIntoView(elements[beginIdx], spot, /* skipOverflowHiddenElements = */ true);
    }
  }

  nextPageMatch() {
    if (this.resumePageIdx !== null) {
      console.error('There can only be one pending page.');
    }
    let matches;
    do {
      const pageIdx = this.offset.pageIdx;
      matches = this.pageMatches[pageIdx];
      if (!matches) {
        // The matches don't exist yet for processing by "matchesReady",
        // so set a resume point for when they do exist.
        this.resumePageIdx = pageIdx;
        break;
      }
    } while (!this.matchesReady(matches));
  }

  advanceOffsetPage(previous) {
    const offset = this.offset;
    const numPages = this.extractTextPromises.length;
    offset.pageIdx = (previous ? offset.pageIdx - 1 : offset.pageIdx + 1);
    offset.matchIdx = null;

    this.pagesToSearch--;

    if (offset.pageIdx >= numPages || offset.pageIdx < 0) {
      offset.pageIdx = (previous ? numPages - 1 : 0);
      offset.wrapped = true;
    }
  }

  updateMatch(found) {
    let state = PDFFindBar.FindStates.FIND_NOTFOUND;
    const wrapped = this.offset.wrapped;
    this.offset.wrapped = false;

    if (found) {
      const previousPage = this.selected.pageIdx;
      this.selected.pageIdx = this.offset.pageIdx;
      this.selected.matchIdx = this.offset.matchIdx;
      state = (wrapped ? PDFFindBar.FindStates.FIND_WRAPPED : PDFFindBar.FindStates.FIND_FOUND);
      // Update the currently selected page to wipe out any selected matches.
      if (previousPage !== -1 && previousPage !== this.selected.pageIdx) {
        this.updatePage(previousPage);
      }
    }

    this.updateUIState(state, this.state.findPrevious);
    if (this.selected.pageIdx !== -1) {
      this.updatePage(this.selected.pageIdx);
    }
  }

  updateUIResultsCount() {
    if (this.findBar === null) {
      throw new Error('PDFFindController is not initialized with a PDFFindBar instance.');
    }
    this.findBar.updateResultsCount(this.matchCount);
  }

  updateUIState(state, previous) {
    if (this.integratedFind) {
      FirefoxCom.request('updateFindControlState', { result: state, findPrevious: previous });
      return;
    }
    if (this.findBar === null) {
      throw new Error('PDFFindController is not initialized with a PDFFindBar instance.');
    }
    this.findBar.updateUIState(state, previous, this.matchCount);
  }
}
