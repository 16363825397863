var DEFAULT_PREFERENCES = {
    showPreviousViewOnLoad: true,
    defaultZoomValue: '',
    sidebarViewOnLoad: 0,
    enableHandToolOnLoad: false,
    enableWebGL: false,
    pdfBugEnabled: false,
    disableRange: true,
    disableStream: false,
    disableAutoFetch: false,
    disableFontFace: false,
    disableTextLayer: false,
    useOnlyCssZoom: false,
    externalLinkTarget: 0,
};

var Preferences = {
    prefs: Object.create(DEFAULT_PREFERENCES),
    isInitializedPromiseResolved: false,
    initializedPromise: null,

    /**
     * Initialize and fetch the current preference values from storage.
     * @return {Promise} A promise that is resolved when the preferences
     *                   have been initialized.
     */
    initialize: function preferencesInitialize() {
        return this.initializedPromise =
            this._readFromStorage(DEFAULT_PREFERENCES).then(function(prefObj) {
                this.isInitializedPromiseResolved = true;
                if (prefObj) {
                    this.prefs = prefObj;
                }
            }.bind(this));
    },

    /**
     * Reset the preferences to their default values and update storage.
     * @return {Promise} A promise that is resolved when the preference values
     *                   have been reset.
     */
    reset: function preferencesReset() {
        return this.initializedPromise.then(function() {
            this.prefs = Object.create(DEFAULT_PREFERENCES);
            return this._writeToStorage(DEFAULT_PREFERENCES);
        }.bind(this));
    },

    /**
     * Replace the current preference values with the ones from storage.
     * @return {Promise} A promise that is resolved when the preference values
     *                   have been updated.
     */
    reload: function preferencesReload() {
        return this.initializedPromise.then(function () {
            this._readFromStorage(DEFAULT_PREFERENCES).then(function(prefObj) {
                if (prefObj) {
                    this.prefs = prefObj;
                }
            }.bind(this));
        }.bind(this));
    },

    /**
     * Set the value of a preference.
     * @param {string} name The name of the preference that should be changed.
     * @param {boolean|number|string} value The new value of the preference.
     * @return {Promise} A promise that is resolved when the value has been set,
     *                   provided that the preference exists and the types match.
     */
    set: function preferencesSet(name, value) {
        return this.initializedPromise.then(function () {
            if (DEFAULT_PREFERENCES[name] === undefined) {
                throw new Error('preferencesSet: \'' + name + '\' is undefined.');
            } else if (value === undefined) {
                throw new Error('preferencesSet: no value is specified.');
            }
            var valueType = typeof value;
            var defaultType = typeof DEFAULT_PREFERENCES[name];

            if (valueType !== defaultType) {
                if (valueType === 'number' && defaultType === 'string') {
                    value = value.toString();
                } else {
                    throw new Error('Preferences_set: \'' + value + '\' is a \"' +
                        valueType + '\", expected \"' + defaultType + '\".');
                }
            } else {
                if (valueType === 'number' && (value | 0) !== value) {
                    throw new Error('Preferences_set: \'' + value +
                        '\' must be an \"integer\".');
                }
            }
            this.prefs[name] = value;
            return this._writeToStorage(this.prefs);
        }.bind(this));
    },

    /**
     * Get the value of a preference.
     * @param {string} name The name of the preference whose value is requested.
     * @return {Promise} A promise that is resolved with a {boolean|number|string}
     *                   containing the value of the preference.
     */
    get: function preferencesGet(name) {
        return this.initializedPromise.then(function () {
            var defaultValue = DEFAULT_PREFERENCES[name];

            if (defaultValue === undefined) {
                throw new Error('preferencesGet: \'' + name + '\' is undefined.');
            } else {
                var prefValue = this.prefs[name];

                if (prefValue !== undefined) {
                    return prefValue;
                }
            }
            return defaultValue;
        }.bind(this));
    }
};

Preferences._writeToStorage = function (prefObj) {
    return new Promise(function (resolve) {
        localStorage.setItem('pdfjs.preferences', JSON.stringify(prefObj));
        resolve();
    });
};

Preferences._readFromStorage = function (prefObj) {
    return new Promise(function (resolve) {
        var readPrefs = JSON.parse(localStorage.getItem('pdfjs.preferences'));
        resolve(readPrefs);
    });
};

export default Preferences;
