import $ from 'jquery';

$(() => {
  const $body = $('body');

  $body.on('click', '.close-fullscreen-viewer', () => {
    window.parent.closeAllFullscreen();
  });

  $body.on('click', '.fullscreen_parent_link', (e) => {
    const href = $(e.currentTarget).attr('href');
    window.parent.location.href = href;
    return false;
  });
});
