import $ from 'jquery';
import Routing from 'routing';
import E1Request from '../classes/E1Request';
import Form from '../classes/Form';

$(() => {
  const $body = $('body');
  $body.on('click', '.searchEmail', function () {
    const _slug = $(this).data('slug');
    searchAddressBook(_slug);
  });
  $body.on('submit', 'form.add-user-form', function (e) {
    e.preventDefault();

    const $form = $(this);
    const magicForm = new Form($form);

    magicForm.submit();
    return false;
  });
  $body.on('submit', 'form.search-email-form', function (e) {
    e.preventDefault();
    const _slug = $(this).attr('data-slug');
    const _type = $(this).attr('data-type');
    const $form = $(this);
    const magicForm = new Form($form);
    magicForm.extraCallback = function (response) {
      if (response != null) {
        if (response.search_result != null) {
          verificationSent(response.email_address);
        } else if (response.new_account) {
          newAddressBook(_slug, response.email_address);
        } else if (response.multiple_contacts) {
          selectContact(_slug, response.email_address);
        }
      }
    };
    magicForm.submit();
    return false;
  });
  $body.on('submit', 'form.select-contact-form', function (e) {
    e.preventDefault();

    const $form = $(this);
    const magicForm = new Form($form);

    magicForm.extraCallback = function (response) {
      if (response != null) {
        window.location = window.location;
      }
    };

    magicForm.submit();
    return false;
  });
  if ($body.attr('data-multiple')) {
    selectContact();
  }
});

export function searchAddressBook(slug) {
  const requestNew = Routing.generate('itp_slugaddressbook_emailsearchmodal', { slug });
  const data = { type: $(this).data('type') };
  const req = new E1Request(requestNew, 'GET', data);
  req.show_loading_modal = true;
  req.submit();
}
function newAddressBook(slug, email) {
  const accountNew = Routing.generate('itp_slugaddressbook_addmodal', { slug });
  const data = { email };
  const req = new E1Request(accountNew, 'POST', data);
  req.show_loading_modal = true;
  req.submit();

  setTimeout(() => {
    $('#address_book_contact_company_trades').select2({
      theme: 'bootstrap',
      maximumSelectionSize: 10,
      placeholder: 'Click to Select Trades...',
    });
  }, 500);
}
function verificationSent(email_address) {
  $('#verification-email-address').text(email_address);
  $('.form-body').hide();
  $('.form-footer').hide();
  $('.verification-sent').show();
  $('.verification-close').show();
}
function selectContact(slug, email) {
  const _slug = $('body').attr('data-slug');

  const requestNew = Routing.generate('itp_contactAuth', { slug: _slug });
  const data = { type: $(this).data('type') };
  const req = new E1Request(requestNew, 'GET', data);
  req.submit();
}
